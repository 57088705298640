const categories = [
	{
		id: 1,
		nom: "Via ferrada",
		slug: "via",
	},
	{
		id: 2,
		nom: "Barranquisme",
		slug: "barranquisme",
	},
	{
		id: 3,
		nom: "Ruta",
		slug: "ruta",
	},
	{
		id: 4,
		nom: "Escalada",
		slug: "escalada",
	},
	{
		id: 5,
		nom: "Acuàtic",
		slug: "acuatic",
	},
];

export default categories;
