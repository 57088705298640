import { Circle } from "@mui/icons-material";
import { Grid, Typography, useMediaQuery } from "@mui/material";
import { Box, Container } from "@mui/system";
import { useTranslation } from "react-i18next";
import theme from "../../../../theme";

export default function QueEs() {
	const matches = useMediaQuery("(min-width:960px)");
	const { t } = useTranslation();
	return (
		<Box
			backgroundColor={theme.palette.background.secondary}
			mt={-1}
			py={10}
		>
			<Container>
				<Typography variant="h1" color="white">
					{t("Què és?")}
				</Typography>
				<Grid spacing={5} container mt={4}>
					<Grid item md={6}>
						<Typography variant="body1" color="white">
							{t(
								"Es un espai privat a l’aire lliure  propietat de l’Ajuntament de Vallcebre i que amb l’autorització dels propietaris de les finques particulars es pot accedir a partir d’un forfait d’entrada obligatori durant tot l’any."
							)}
						</Typography>
						<Typography color="white">
							{t("Aquest accés regulat te com a objectius:")}
						</Typography>
					</Grid>
					<Grid item md={6}>
						<Typography
							color="white"
							display="flex"
							alignItems={"flex-start"}
						>
							<Circle fontSize="10" style={{ marginTop: 3 }} />
							{t("Garantir la seguretat dels i les usuàries")}
						</Typography>
						<Typography
							color="white"
							display="flex"
							alignItems={"flex-start"}
						>
							<Circle fontSize="10" style={{ marginTop: 3 }} />
							{t(
								"Garantir el manteniment i bon estat de les instal·lacions"
							)}
						</Typography>
						<Typography
							color="white"
							display="flex"
							alignItems={"flex-start"}
						>
							<Circle fontSize="10" style={{ marginTop: 3 }} />
							{t(
								"Garantir un accés ordenat i un ús correcte de les instal·lacions"
							)}
						</Typography>
						<Typography
							color="white"
							display="flex"
							alignItems={"flex-start"}
						>
							<Circle fontSize="10" style={{ marginTop: 3 }} />
							{t(
								"Crear ocupació al municipi i reactivar-lo econòmicament"
							)}
						</Typography>
					</Grid>
				</Grid>
			</Container>
		</Box>
	);
}
