import { Box, Container, Grid, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import ReservaAdmin from "../../../components/Activitats/ReservaAdmin";
import CustomButton from "../../../components/CustomButton";
import KPI from "../../../components/KPI";
import LoadingSkeleton from "../../../components/Layout/LoadingSkeleton";
import Title from "../../../components/Layout/Title";
import ReservaSkeleton from "../../../components/Skeletons/ReservaSkeleton";
import { getElementsAdmin } from "../../../database/API";

const useStyles = makeStyles((theme) => ({
	root: {
		backgroundColor: theme.palette.background.dark,
		minHeight: "100%",
	},
	container: {
		paddingBottom: 80,
	},
	"@global": {
		html: {
			width: "100%",
			height: "100%",
		},
	},
}));

const HomeAdmin = () => {
	const classes = useStyles();
	const [reserves, setReserves] = useState([]);
	const [loading, setLoading] = useState(true);
	const navigate = useNavigate();

	useEffect(() => {
		const get = async () => {
			const res = await getElementsAdmin("esntradesProx");
			setReserves(res.result);
			setLoading(false);
		};
		get();
	}, []);

	return (
		<Box className={classes.container}>
			<Title title="Inici" />
			<Container maxWidth="xl">
				<Box pt={2} mb={4} spacing={3}>
					<KPI />
				</Box>
				<Typography variant="h5" mb={2}>
					Entrades pròximes
				</Typography>
				<LoadingSkeleton
					loading={loading}
					skeleton={<ReservaSkeleton />}
				>
					<>
						{reserves?.map((item) => (
							<ReservaAdmin reserva={item} />
						))}
					</>
				</LoadingSkeleton>

				<CustomButton
					title="Totes les entrades"
					onClick={() => navigate("/admin/entrades")}
					fullWidth
				/>
			</Container>
		</Box>
	);
};

export default HomeAdmin;
