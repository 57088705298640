import { Box, Grid, Tooltip, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useNavigate } from "react-router";
import { useTranslation } from "react-i18next";
import { ReactComponent as Aproximacio } from "../../../assets/img/icons/aproximacio.svg";
import { ReactComponent as Dificultat1 } from "../../../assets/img/icons/dificultat1.svg";
import { ReactComponent as Dificultat2 } from "../../../assets/img/icons/dificultat2.svg";
import { ReactComponent as Dificultat3 } from "../../../assets/img/icons/dificultat3.svg";
import { ReactComponent as Desnivell } from "../../../assets/img/icons/desnivell.svg";
import { ReactComponent as Llargada } from "../../../assets/img/icons/llargada.svg";
import { ReactComponent as Temps } from "../../../assets/img/icons/temps.svg";
import { ReactComponent as Acuatic } from "../../../assets/img/icons/acuatic.svg";
import { ReactComponent as Barranquisme } from "../../../assets/img/icons/barranquisme.svg";
import { ReactComponent as Escalada } from "../../../assets/img/icons/escalada.svg";
import { ReactComponent as Ruta } from "../../../assets/img/icons/ruta.svg";
import { ReactComponent as Via } from "../../../assets/img/icons/via.svg";
import theme from "../../../theme";
import Icons from "./components/Icons";

const responsive = {
	0: { items: 1 },
	568: { items: 1 },
	1024: { items: 1 },
};

export default function EspaiItem({ espai }) {
	const classes = useStyles();
	let navigate = useNavigate();
	const { t } = useTranslation();

	return (
		<Grid
			item
			md={4}
			sm={12}
			xs={12}
			onClick={() => navigate("/espais/" + espai.id, { state: espai })}
		>
			<Box className={classes.wrap}>
				<Box>
					<Box className={classes.header}>
						<Cat nom={espai?.cat_nom} />
						{espai?.pagament ? (
							<Box className={classes.pagament}>
								<Typography
									variant="h6"
									className={classes.textPagament}
									fontSize={12}
								>
									{t("Accés de pagament")}
								</Typography>
							</Box>
						) : (
							""
						)}
					</Box>
					<Box className={classes.imageWrap}>
						<img
							src={espai?.foto}
							className={classes.imatge}
							alt={"Foto " + espai?.nom}
						/>
					</Box>
					<Typography variant="h3" fontSize={28} px={2} pt={2}>
						{t(espai?.nom)}
					</Typography>
				</Box>
				<Box></Box>
				<Box className={classes.body}>
					<Box
						display="flex"
						alignItems="center"
						justifyContent="space-between"
					>
						<Icons
							Icon={Aproximacio}
							value={espai?.aproximacio}
							append="min"
							nom={t("Aproximació")}
						/>
						<Icons
							Icon={
								espai?.dificultat === "F" ||
								espai?.dificultat === "K1"
									? Dificultat1
									: espai?.dificultat === "D" ||
									  espai?.dificultat === "K3"
									? Dificultat2
									: Dificultat3
							}
							value={espai?.dificultat}
							nom={t("Dificultat")}
						/>
						<Icons
							Icon={Desnivell}
							value={espai?.desnivell}
							append="m"
							nom={t("Desnivell")}
						/>
						<Icons
							Icon={Llargada}
							value={espai?.llargada}
							append="m"
							nom={t("Llargada")}
						/>
						<Icons
							Icon={Temps}
							value={espai?.temps}
							append="min"
							nom={t("Temps")}
						/>
					</Box>
				</Box>

				{/* <Box className={classes.slide}>
					<CustomPublicButton
						onClick={() => navigate("/espais/" + espai.id)}
						fullWidth
						title={t("Més info")}
					/>
				</Box> */}
			</Box>
		</Grid>
	);
}

const Cat = ({ nom }) => {
	const classes = useStyles();
	const { t } = useTranslation();
	let Cat = "";
	switch (nom) {
		case "Via ferrada":
			Cat = Via;
			break;
		case "Barranquisme":
			Cat = Barranquisme;
			break;
		case "Ruta":
			Cat = Ruta;
			break;
		case "Acuàtic":
			Cat = Acuatic;
			break;
		case "Escalada":
			Cat = Escalada;
			break;
		default:
			break;
	}
	return (
		<Box display={"flex"} alignItems={"center"} className={classes.cat}>
			<Cat className={classes.catIcon} />{" "}
			<Typography variant="h5">{t(nom)}</Typography>
		</Box>
	);
};

const useStyles = makeStyles((theme) => ({
	wrap: {
		backgroundColor: "white",
		borderRadius: 15,
		textAlign: "left",
		height: "100%",
		display: "flex",
		flexDirection: "column",
		justifyContent: "space-between",
		transition: "0.2s",
		border: "1px solid " + theme.palette.icon.wrap,
		cursor: "pointer",
		overflow: "hidden",
		position: "relative",
		zIndex: 0,
		"&:hover": {
			boxShadow: "0px 0px 5px 0px " + theme.palette.icon.wrap,
			transform: "scale(1.05)",
		},
	},
	header: {
		padding: 5,
		backgroundColor: theme.palette.icon.wrap,
		display: "flex",
		justifyContent: "space-between",
	},
	body: {
		textAlign: "left",
		padding: 20,
		"& p": {
			fontFamily: "Montserrat",
			textAlign: "left",
		},
	},
	imageWrap: {
		height: 150,
		overflow: "hidden",
	},
	imatge: {
		width: "100%",
	},
	icon: {
		width: 40,
		fill: theme.palette.icon.main,
	},
	iconWrap: {
		width: 30,
		height: 30,
		backgroundColor: theme.palette.icon.wrap,
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
	},
	pagament: {
		top: 10,
		right: 10,
		width: 45,
		height: 45,
		padding: 5,
		backgroundColor: "black",
		borderRadius: 40,
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		zIndex: 10,
	},
	textPagament: {
		color: "#F29300 !important",
		textAlign: "center",
		lineHeight: "11px !important",
		textTransform: "none !important",
	},
	cat: {
		top: 0,
		left: 0,
		padding: 10,
		// borderBottomRightRadius: 30,
	},
	catIcon: {
		width: 40,
		height: 40,
	},
}));
