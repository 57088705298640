import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
	AppBar,
	Box,
	Container,
	Drawer,
	Hidden,
	IconButton,
	List,
	Toolbar,
	Tooltip,
	Typography,
	useMediaQuery,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Menu, ShoppingBag, ShoppingCart } from "react-feather";
import NavBarItem from "./NavBarItem";
import { Close, Instagram } from "@mui/icons-material";
import NavBarItemMobile from "./NavBarItemMobile";
import clsx from "clsx";
import { useTranslation } from "react-i18next";
import LangSelector from "../../lang/LangSelector";
import XXSS from "../../components/Layout/XXSS";
import { ReactComponent as Logo } from "../../assets/logo.svg";
import theme from "../../theme";
import { useNavigate } from "react-router";
import CustomPublicButton from "../../components/CustomPublicButton";

const useStyles = makeStyles((theme) => ({
	main: {
		width: "100%",
		zIndex: 100,
		position: "fixed",
	},
	nav: {
		transition: "0.2s",
		paddingLeft: "0 !important",
		paddingRight: "0 !important",
	},
	navBar: {
		backgroundColor: theme.palette.background.main,
		boxShadow: "none",
	},
	navBarDown: {
		backgroundColor: theme.palette.background.main,
		boxShadow: "none",
	},
	logoDown: {
		position: "absolute",
	},
	logo: {
		position: "absolute",
		backgroundColor: theme.palette.primary.main,
		borderRadius: 10,
		paddingTop: 10,
		paddingBottom: 10,
		paddingLeft: 30,
		paddingRight: 30,
		transition: "0.2s",
	},
}));

const TopBarPublic = ({ className, onMobileNavOpen, ...rest }) => {
	const classes = useStyles();
	const [menu, setMenu] = useState(null);
	const [scrollY, setScrollY] = useState(window.scrollY);
	const { t } = useTranslation();
	const navigate = useNavigate();
	const matches = useMediaQuery("(min-width:1430px)");

	const items = [
		{
			title: t("Inici"),
			to: "",
		},
		{
			title: t("Què és?"),
			to: "benvinguts",
		},
		{
			title: t("Horaris i tarifes"),
			to: "horaris",
		},
		{
			title: t("Espais"),
			to: "espais",
		},
		{
			title: t("Activitats guiades"),
			href: "https://pendolguies.com/activitats",
		},
		{
			title: t("Lloguer"),
			href: "https://pendolguies.com/lloguer",
		},
	];
	const openMenu = (event) => {
		setMenu(event.currentTarget);
	};

	const closeMenu = () => {
		setMenu(null);
	};

	useEffect(() => {
		window.addEventListener("scroll", handleScroll);
		return () => {
			window.removeEventListener("scroll", handleScroll);
		};
	});

	const handleScroll = () => {
		setScrollY(window.scrollY);
	};

	return (
		<Box className={classes.main}>
			<AppBar elevation={0} className={classes.navBar}>
				<Toolbar
					className={clsx(
						classes.nav,
						scrollY > 200 ? classes.navBarDown : classes.navBar
					)}
				>
					<Hidden lgDown>
						<Box
							style={{
								flex: 1,
								display: "flex",
								justifyContent: "flex-start",
								height: 64,
							}}
						>
							{items?.map((item) => (
								<NavBarItem
									to={item.to}
									key={item.title}
									title={item.title}
									href={item.href}
								/>
							))}
						</Box>
					</Hidden>
					<Hidden lgDown>
						<Box
							style={{
								flex: 0,
								display: "flex",
								justifyContent: "center",
								height: 64,
							}}
						>
							<Box
								onClick={() => navigate("/")}
								className={
									scrollY > 200
										? classes.logoDown
										: classes.logo
								}
							>
								<Logo
									fill="white"
									width={scrollY > 200 ? 80 : 150}
									style={{ transition: "0.2s" }}
								/>
							</Box>
						</Box>
					</Hidden>
					<Hidden lgDown>
						<Box
							style={{
								flex: 1,
								display: "flex",
								justifyContent: "flex-end",
								alignItems: "center",
								height: 64,
							}}
						>
							<NavBarItem
								to={"/contacte"}
								key={"Contacta i ubicació"}
								title={t("Contacta i ubicació")}
							/>
							<Box display={"flex"} flexDirection="column" ml={2}>
								<Box mb={0.5}>
									<CustomPublicButton
										title={matches ? "@pendolguies" : ""}
										xsmall
										Icon={Instagram}
										onClick={() =>
											window.open(
												"https://www.instagram.com/pendolguies/",
												"_blank"
											)
										}
									/>
								</Box>

								<CustomPublicButton
									title={
										matches ? "@espaiactiuvallcebre" : ""
									}
									xsmall
									Icon={Instagram}
									onClick={() =>
										window.open(
											"https://www.instagram.com/espaiactiuvallcebre/",
											"_blank"
										)
									}
								/>
							</Box>
							<Box mx={2}>
								<CustomPublicButton
									title={t("ENTRADES")}
									small
									Icon={ShoppingCart}
									onClick={() => navigate("/entrades")}
								/>
							</Box>
							{/* <XXSS white /> */}
							<LangSelector />
						</Box>
					</Hidden>
					<Hidden lgUp>
						<Box onClick={() => navigate("/")} p={1}>
							<Logo fill="white" width={60} />
						</Box>
						<Box flexGrow={1} />
						<IconButton
							style={{ zIndex: 10 }}
							color="inherit"
							aria-controls="simple-menu"
							aria-haspopup="true"
							onClick={openMenu}
							className={classes.buttonDrawer}
						>
							<Menu />
						</IconButton>
						<Drawer open={menu} onClose={closeMenu}>
							<IconButton>
								<Close onClick={closeMenu} />
							</IconButton>
							{items?.map((item) => (
								<NavBarItemMobile
									to={item.to}
									key={item.title}
									title={item.title}
									closeMenu={closeMenu}
									href={item.href}
								/>
							))}
							<NavBarItemMobile
								to={"/contacte"}
								key={"Contacta i ubicació"}
								title={t("Contacta i ubicació")}
								closeMenu={closeMenu}
							/>

							<Box display={"flex"} flexDirection="column" ml={2}>
								<Box my={2}>
									<CustomPublicButton
										title={t("ENTRADES")}
										small
										Icon={ShoppingCart}
										onClick={() => {
											closeMenu();
											navigate("/entrades");
										}}
									/>
								</Box>
								<Box mb={0.5}>
									<Tooltip
										title="Pèndol Guies"
										placement="left"
									>
										<CustomPublicButton
											title={"@pendolguies"}
											xsmall
											Icon={Instagram}
											onClick={() => {
												closeMenu();
												window.open(
													"https://www.instagram.com/pendolguies/",
													"_blank"
												);
											}}
										/>
									</Tooltip>
								</Box>
								<Box mb={2}>
									<Tooltip
										title="Espai Actiu Vallcebre"
										placement="left"
									>
										<CustomPublicButton
											title={"@espaiactiuvallcebre"}
											xsmall
											Icon={Instagram}
											onClick={() => {
												closeMenu();
												window.open(
													"https://www.instagram.com/espaiactiuvallcebre/",
													"_blank"
												);
											}}
										/>
									</Tooltip>
								</Box>
							</Box>
							{/* <XXSS /> */}
							<LangSelector mobile />
						</Drawer>
					</Hidden>
				</Toolbar>
			</AppBar>
		</Box>
	);
};

TopBarPublic.propTypes = {
	className: PropTypes.string,
	onMobileNavOpen: PropTypes.func,
};

export default TopBarPublic;
