import React from "react";
import { Box, Container, Grid, Typography, useMediaQuery } from "@mui/material";
import { makeStyles } from "@mui/styles";
import CustomPublicButton from "../../../../components/CustomPublicButton";
import { useNavigate } from "react-router";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
	container: {
		paddingTop: 70,
		paddingBottom: 220,
		textAlign: "center",
	},
}));

export default function Benvinguts() {
	const classes = useStyles();
	const navigate = useNavigate();
	const { t } = useTranslation();

	return (
		<Container maxWidth="sm" className={classes.container}>
			<Typography variant="h1" textAlign="center" marginBottom={5}>
				{t("Benvinguts a Espai Actiu Vallcebre")}
			</Typography>
			<Typography variant="body1" textAlign="center" marginBottom={5}>
				{t(
					"Un lloc ideal per gaudir d’activitats d’aventura en un entorn natural privilegiat. Al municipi de Vallcebre, a l’alt Berguedà, envoltat per la Serra d’Ensija, els Cingles de Vallcebre, el Massís del Pedraforca, el parc natural del Cadí-Moixeró, i uns paisatges espectaculars. Podràs gaudir de les nostres instal·lacions de descens de barrancs, vies ferrades, escalada, camins equipats, i altres activitats per a tots els públics."
				)}
			</Typography>
			<CustomPublicButton
				title={t("Més info")}
				onClick={() => navigate("benvinguts")}
			/>
		</Container>
	);
}
