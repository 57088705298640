import React from "react";
import Page from "../../../components/Layout/Page";
import Sanefa from "../../../components/Sanefa";
import theme from "../../../theme";
import HomeStyle from "../../../theme/styles/HomeStyle";
import Slider from "./Sections/Slider";
import usePageTracking from "../../../layouts/TrakingGA";
import Benvinguts from "./Sections/Benvinguts";
import Espais from "./Sections/Espais";
import BotoContacta from "./Sections/BotoContacta";

const Home = () => {
	const classes = HomeStyle();
	usePageTracking();

	return (
		<Page title="Espai Actiu Vallcebre" className={classes.main}>
			<Slider />
			<Sanefa color="white" />
			<Benvinguts />
			<Sanefa color={theme.palette.background.third} />
			<Espais />
			<Sanefa inverted color={theme.palette.background.third} />
			<BotoContacta />
		</Page>
	);
};

export default Home;
