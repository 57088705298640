const preusEntrada = [
	{
		id: 1,
		nom: "Entrada General",
		preus: [
			{ id: 1, nom: "federat", preu: 3 },
			{ id: 2, nom: "no_federat", preu: 5 },
			{ id: 3, nom: "nen", preu: 3 },
		],
	},
	{
		id: 2,
		nom: "Entrada Barranc",
		preus: [
			{ id: 1, nom: "federat", preu: 2 },
			{ id: 2, nom: "no_federat", preu: 3.5 },
			{ id: 3, nom: "nen", preu: 2 },
		],
	},
];

export default preusEntrada;
