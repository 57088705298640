import {
	Box,
	Container,
	Fade,
	Grid,
	Typography,
	useMediaQuery,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import Header from "../../../components/Header";
import img from "../../../assets/img/espais/barranquisme.jpg";
import Sanefa from "../../../components/Sanefa";
import { makeStyles } from "@mui/styles";
import CategoriaFiltre from "../../../components/CategoriaFiltre";
import { useLocation, useNavigate } from "react-router";
import Page from "../../../components/Layout/Page";
import { useTranslation } from "react-i18next";
import categories from "../../../data/CategoriesData";
import espais from "../../../data/EspaisData";
import EspaiItem from "./EspaiItem";

const EspaisPage = () => {
	const classes = useStyles();
	const { state } = useLocation();
	const [categoria, setCategoria] = useState(state ? state : "");
	const [espaisSel, setEspaisSel] = useState(espais);
	const [changing, setChanging] = useState(false);
	const matches = useMediaQuery("(min-width:960px)");
	const { t, i18n } = useTranslation();
	const navigate = useNavigate();

	const change = (id) => {
		setChanging(true);
		setTimeout(() => {
			if (id) {
				localStorage.setItem("cat", id);
			} else {
				localStorage.removeItem("cat");
			}

			setCategoria(id);
			setChanging(false);
		}, 200);
	};

	useEffect(() => {
		setEspaisSel(
			categoria
				? espais?.filter((item) => item.categoria === categoria)
				: espais
		);
	}, [categoria]);

	return (
		<Page
			className={classes.main}
			title={"Espai Actiu Vallcebre - " + t("Espais")}
		>
			<Header title={t("Espais")} img={img} />
			<Sanefa color="white" />
			<Container>
				<Grid spacing={2} container>
					<Grid item md={12} xs={12}>
						<Typography variant="h2">{t("Categories")}</Typography>

						<Box my={4} justifyContent={"space-between"}>
							<CategoriaFiltre
								set={change}
								categoria={categoria}
							/>
							{categories?.map((item) => (
								<CategoriaFiltre
									cat={item}
									set={change}
									categoria={categoria}
								/>
							))}
						</Box>
					</Grid>
					<Grid item md={12} xs={12}>
						<Fade in={!changing}>
							<Grid
								container
								spacing={5}
								my={2}
								style={{ transition: "0.2s" }}
							>
								{espaisSel?.map((item) => (
									<EspaiItem espai={item} />
								))}
							</Grid>
						</Fade>
					</Grid>
				</Grid>
			</Container>
			<Box className={classes.filters}></Box>
			<Box className={classes.activitats}>
				<Container></Container>
			</Box>
			{/* <Sanefa color="white" />
			<Box className={classes.buscant}>
				<Typography variant="h1">
					{t("No has trobat el què estaves buscant?")}
				</Typography>
				<Box my={2} />
				<Typography variant="body1">
					{t(
						"Contacta amb nosaltres i t'oferirem solucions o alternatives similars"
					)}
				</Typography>
				<Box my={8} />
				<CustomPublicButton
					onClick={() => navigate("/contacte")}
					title={t("Contacta'ns")}
				/>
			</Box> */}
		</Page>
	);
};

export default EspaisPage;

const useStyles = makeStyles((theme) => ({
	filters: {
		paddingTop: 100,
	},
	activitats: {
		paddingBottom: 200,
		textAlign: "center",
		marginTop: -5,
	},
	buscant: {
		paddingTop: 150,
		paddingBottom: 180,
		textAlign: "center",
	},
}));
