const breakpoints = [4320, 2160, 1080, 640, 384, 256, 128];

const unsplashPhotos = [
	{
		id: "canalassa_1.jpeg",
		width: 680,
		height: 480,
	},
	{
		id: "canalassa_2.jpeg",
		width: 680,
		height: 480,
	},
	{
		id: "canalassa_3.jpeg",
		width: 680,
		height: 480,
	},
	{
		id: "canalassa_4.jpeg",
		width: 480,
		height: 680,
	},
	{
		id: "canalassa_5.jpeg",
		width: 680,
		height: 480,
	},
	{
		id: "canalassa_6.jpeg",
		width: 680,
		height: 480,
	},
	{
		id: "canalassa_7.jpeg",
		width: 480,
		height: 680,
	},
	{
		id: "canalassa_8.jpeg",
		width: 680,
		height: 480,
	},
	{
		id: "canalassa_9.jpeg",
		width: 680,
		height: 480,
	},
	{
		id: "canalassa_10.jpeg",
		width: 680,
		height: 480,
	},
	{
		id: "canalassa_11.jpeg",
		width: 680,
		height: 480,
	},
	{
		id: "canalassa_12.jpeg",
		width: 680,
		height: 480,
	},
	{
		id: "canalassa_13.jpeg",
		width: 680,
		height: 480,
	},
	{
		id: "canalassa_14.jpeg",
		width: 680,
		height: 480,
	},
];

const photos = unsplashPhotos.map((photo, index) => {
	const width = photo.width * 4;
	const height = photo.height * 4;
	return {
		src: require("../canalassa/" + photo.id),
		key: `${index}`,
		width,
		height,
		images: breakpoints.map((breakpoint) => {
			const breakpointHeight = Math.round((height / width) * breakpoint);
			return {
				src: require("../canalassa/" + photo.id),
				width: breakpoint,
				height: breakpointHeight,
			};
		}),
	};
});

export default photos;
