import { Breadcrumbs, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { matchRoutes } from "react-router";
import { Link } from "react-router-dom";

const routes = [
	{
		path: "/admin",
		breadcrumbName: "Inici",
		children: [
			{
				path: "/admin/activitats",
				breadcrumbName: "Activitats",
				children: [
					{
						path: "/admin/activitats/add",
						breadcrumbName: "Afegir activitat",
					},
					{
						path: "/admin/activitats/edit/:id",
						breadcrumbName: "Editar activitat",
					},
				],
			},
			{
				path: "/admin/materials",
				breadcrumbName: "Materials",
				children: [
					{
						path: "/admin/materials/add",
						breadcrumbName: "Afegir material",
					},
					{
						path: "/admin/materials/edit/:id",
						breadcrumbName: "Editar material",
					},
				],
			},
			{
				path: "/admin/lloguers",
				breadcrumbName: "Lloguers",
				children: [
					{
						path: "/admin/lloguers/add",
						breadcrumbName: "Afegir lloguer",
					},
					{
						path: "/admin/lloguers/view/:id",
						breadcrumbName: "Detall lloguer",
					},
				],
			},
			{
				path: "/admin/reserves",
				breadcrumbName: "Reserves",
				children: [
					{
						path: "/admin/reserves/add",
						breadcrumbName: "Afegir reserva",
					},
					{
						path: "/admin/reserves/view/:id",
						breadcrumbName: "Detall reserva",
					},
				],
			},
			{
				path: "/admin/calendari",
				breadcrumbName: "Calendari",
			},
		],
	},
];

const useStyles = makeStyles((theme) => ({
	bcPrev: {
		textDecoration: "none",
	},
	bcAct: {
		color: theme.palette.text.primary + " !important",
	},
}));

const Breadcrumb = ({ locationPath }) => {
	let matchedRoutes = matchRoutes(routes, locationPath);
	const classes = useStyles();

	return (
		<div>
			<Breadcrumbs separator="›" className="breadcrumb">
				{matchedRoutes?.map((matchRoute, i) => {
					const { path, breadcrumbName } = matchRoute?.route;
					const isActive = path === locationPath;

					return isActive ? (
						<Typography
							variant="caption"
							key={i}
							className={classes.bcAct}
						>
							{breadcrumbName}
						</Typography>
					) : (
						<Link to={path} key={i} className={classes.bcPrev}>
							<Typography variant="caption">
								{breadcrumbName}
							</Typography>
						</Link>
					);
				})}
			</Breadcrumbs>
		</div>
	);
};

export default Breadcrumb;
