import canalassa from "../assets/img/espais/canalassa/photos";
import empalomar from "../assets/img/espais/empalomar/photos";
import cal_curt from "../assets/img/espais/cal_curt/photos";
import forat_negre from "../assets/img/espais/forat_negre/photos";
import cami_aeri from "../assets/img/espais/cami_aeri/photos";

const espais = [
	{
		id: 1,
		nom: "Via ferrada La Canalassa",
		categoria: 1,
		cat_nom: "Via ferrada",
		aproximacio: 20,
		temps: 30,
		dificultat: "K1",
		desnivell: 40,
		llargada: 150,
		escapatoria: 0,
		foto: require("../assets/img/espais/canalassa.jpg"),
		mes: require("../assets/docs/canalassa.pdf"),
		mapa: require("../assets/img/mapa_canalassa.jpg"),
		fotos: canalassa,
		pagament: 1,
		guiada: "https://pendolguies.com/activitats/3",
		desc: "Una via ferrada pensada pels més petits de la família i per aquelles persones que volen iniciar-se en aquest món vertical. Un itinerari poc exigent, on podrem fer el primer tastet, on és possible combinar l’activitat amb la seva germana gran, la via ferrada de les Roques de l’Empalomar.",
	},
	{
		id: 2,
		nom: "Via ferrada de l'Empalomar",
		categoria: 1,
		cat_nom: "Via ferrada",
		aproximacio: 25,
		temps: 80,
		dificultat: "K3",
		desnivell: 130,
		llargada: 300,
		escapatoria: 1,
		foto: require("../assets/img/espais/empalomar.jpg"),
		mes: require("../assets/docs/empalomar.pdf"),
		mapa: require("../assets/img/mapa_empalomar.jpg"),
		fotos: empalomar,
		pagament: 1,
		guiada: "https://pendolguies.com/activitats/29",
		desc: "Situada al costat del poble de Vallcebre, en el Berguedà, podem gaudir d’aquesta espectacular via ferrada d’iniciació, on la seva verticalitat ens farà experimentar la sensació d’una gran escalada en un entorn immillorable",
	},
	{
		id: 3,
		nom: "Via ferrada Cal Curt",
		categoria: 1,
		cat_nom: "Via ferrada",
		aproximacio: 25,
		temps: 60,
		dificultat: "K5",
		desnivell: 60,
		llargada: 200,
		escapatoria: 1,
		foto: require("../assets/img/espais/via.jpg"),
		mes: require("../assets/docs/cal-curt.pdf"),
		mapa: require("../assets/img/mapa_cal_curt.jpg"),
		fotos: cal_curt,
		pagament: 1,
		guiada: "https://pendolguies.com/activitats/32",
		desc: "De les vies ferrades que trobem a Vallcebre, aquesta és la de major dificultat. Tot i tenir una equipació excel·lent, els seus desploms poc pronunciats ens faran treure tota la nostra força de braços. Es un gran repte per els que tenen força expèriencia.",
	},
	{
		id: 4,
		nom: "Barranquisme Torrent del forat negre",
		categoria: 2,
		cat_nom: "Barranquisme",
		aproximacio: 25,
		temps: 60,
		dificultat: "MD",
		desnivell: 60,
		llargada: 200,
		escapatoria: 1,
		foto: require("../assets/img/espais/barranquisme.jpg"),
		mes: require("../assets/docs/forat-negre.pdf"),
		mapa: require("../assets/img/mapa_barrancs.jpg"),
		fotos: forat_negre,
		pagament: 1,
		guiada: "https://pendolguies.com/activitats/22",
		desc: "Barranc ideal per iniciar-se amb la tècnica del ràpel, ja que la primera part, ens posaran a prova 5 ràpels d’entre 10 i 15 metres. En la segona part, després de passar per la cova que dóna nom al barranc, gaudirem de passos més engorjats, on trobarem salts, desgrimpades i algun ràpel més.",
	},
	{
		id: 5,
		nom: "Escola d'escalada l'Empalomar",
		categoria: 4,
		cat_nom: "Escalada",
		aproximacio: 10,
		dificultat: "F",
		desnivell: 15,
		foto: require("../assets/img/espais/escalada.jpg"),
		mes: require("../assets/docs/escalada-empalomar.pdf"),
		mapa: require("../assets/img/mapa_escalada.jpg"),
		pagament: 1,
	},
	{
		id: 6,
		nom: "Camí Aeri de l'Artic",
		categoria: 3,
		cat_nom: "Ruta",
		aproximacio: 30,
		temps: 90,
		dificultat: "F",
		desnivell: 130,
		llargada: 1600,
		foto: require("../assets/img/espais/cami_aeri.jpg"),
		mes: require("../assets/docs/artic.pdf"),
		mapa: require("../assets/img/mapa_aeri.jpg"),
		fotos: cami_aeri,
		pagament: 0,
		guiada: "https://pendolguies.com/activitats/52",
		desc: "El Camí Aeri de l'Àrtic és un trekking equipat ubicat a la població de Vallcebre. És un itinerari especial, ja que tot i no necessitar-se material tècnic per realitzar-se ens endinsa en el món de les grimpades i crestes. Un recorregut fàcil, no recomenat per persones amb vertigen ja que té alguns punts on la sensació d'alçada és més exposada i gràcies als esglaons i algunes equipacions podrem progressar sense més dificultat. A més a més, gaudirem d'unes vistes espectaculars de la pròpia cresta, els Cingles de Conangles i el Parc Natural de Cadí-Moixeró.",
	},
	{
		id: 6,
		nom: "Llac de Tumí",
		categoria: 5,
		cat_nom: "Acuàtic",
		aproximacio: 30,
		temps: 90,
		dificultat: "F",
		foto: require("../assets/img/espais/llac.jpg"),
		mapa: require("../assets/img/mapa.jpg"),
		pagament: 1,
		guiada: "https://pendolguies.com/activitats/55",
		desc: "El Llac de Tumí és un punt d'elevat interès geològic i paleontològic. L'existència d'un llac artificial resultat de les antigues explotacions mineres a la zona, confereixen a l'indret un interès paisatgístic afegit. Està situat a peu de la carretera que va de Vallcebre a Coll de Pradell, en sentit Saldes. Si vols apropar-te a les petjades de dinosaures amb els nostres paddle surf o donar-te un bany refrescant fent una volta en canoa, no dubtis a posar-te en contacte per llogar el teu material! T’esperem!",
	},
];

export default espais;
