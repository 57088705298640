import React from "react";
import { Box, useMediaQuery } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { ReactComponent as Icon } from "../../../../assets/logo.svg";
import theme from "../../../../theme";
import ReactPlayer from "react-player";

export default function Slider() {
	const classes = useStyles();
	const matches = useMediaQuery("(min-width:960px)");

	return (
		<Box className={classes.header}>
			<ReactPlayer
				playing={true}
				loop
				url={[
					{
						src: "https://pendolguies.covcontrol.net/public/storage/espai_actiu_video.mp4",
					},
				]}
				height="100%"
				width="100%"
				className={classes.player}
				muted
			/>

			<Box className={classes.overlay}>
				<Icon
					alt="Logo Espai Actiu Vallcebre"
					className={classes.logo}
					fill={theme.palette.primary.main}
				/>
			</Box>
			<Box className={classes.overlay2}></Box>
		</Box>
	);
}

const useStyles = makeStyles((theme) => ({
	header: {
		position: "relative",
		paddingTop: "56.25%",
		[theme.breakpoints.down("sm")]: {
			paddingTop: "70.25%",
		},
	},
	overlay: {
		position: "absolute",
		width: "100%",
		height: "100%",
		top: 0,
		bottom: 0,
		justifyContent: "center",
		display: "flex",
		flex: 1,
		alignItems: "center",
		zIndex: 10,
	},
	overlay2: {
		position: "absolute",
		opacity: 0.5,
		backgroundColor: "black",
		width: "100%",
		height: "100%",
		top: 0,
		bottom: 0,
		justifyContent: "center",
		display: "flex",
		flex: 1,
		alignItems: "center",
	},
	logo: {
		width: 400,
		marginBottom: 120,
		[theme.breakpoints.down("md")]: {
			width: 400,
			marginBottom: 80,
		},
		[theme.breakpoints.down("sm")]: {
			width: 150,
			marginBottom: 10,
		},
		"& .cls-1": {
			fill: "white",
			opacity: 1,
		},
	},
	logoMobile: {
		width: 250,
	},
	player: {
		position: "absolute",
		top: 0,
		left: 0,
		width: "100%",
		height: "100%",
	},
}));
