const breakpoints = [4320, 2160, 1080, 640, 384, 256, 128];

const unsplashPhotos = [
	{
		id: "cami_aeri_1.jpg",
		width: 680,
		height: 480,
	},
	{
		id: "cami_aeri_2.jpg",
		width: 680,
		height: 480,
	},
	{
		id: "cami_aeri_3.jpg",
		width: 680,
		height: 480,
	},
	{
		id: "cami_aeri_4.jpg",
		width: 680,
		height: 480,
	},
	{
		id: "cami_aeri_5.jpg",
		width: 680,
		height: 480,
	},
	{
		id: "cami_aeri_6.jpg",
		width: 680,
		height: 480,
	},
];

const photos = unsplashPhotos.map((photo, index) => {
	const width = photo.width * 4;
	const height = photo.height * 4;
	return {
		src: require("../cami_aeri/" + photo.id),
		key: `${index}`,
		width,
		height,
		images: breakpoints.map((breakpoint) => {
			const breakpointHeight = Math.round((height / width) * breakpoint);
			return {
				src: require("../cami_aeri/" + photo.id),
				width: breakpoint,
				height: breakpointHeight,
			};
		}),
	};
});

export default photos;
