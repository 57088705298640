import React, { useEffect, useState } from "react";
import { Box, Container, Typography, useMediaQuery } from "@mui/material";
import { makeStyles } from "@mui/styles";
import CustomPublicButton from "../../../../components/CustomPublicButton";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";

export default function BotoContacta() {
	const classes = useStyles();
	const matches = useMediaQuery("(min-width:960px)");
	const { t } = useTranslation();
	const navigate = useNavigate();

	return (
		<Box className={classes.container}>
			<Container maxWidth="lg">
				<Box
					display={matches ? "flex" : "auto"}
					justifyContent={"space-around"}
					alignItems="center"
					textAlign={"center"}
				>
					<Box textAlign={"left"}>
						<Typography variant="h1" textAlign={"center"} mb={3}>
							{t("Tens algun dubte?")}
						</Typography>
					</Box>
					<Box>
						<CustomPublicButton
							title={t("Contacte")}
							onClick={() => navigate("/contacte")}
						/>
					</Box>
				</Box>
			</Container>
		</Box>
	);
}

const useStyles = makeStyles((theme) => ({
	container: {
		paddingTop: 150,
		paddingBottom: 350,
		textAlign: "center",
		marginTop: -5,
		marginBottom: -5,
		zIndex: 10,
	},
	image: {
		height: 80,
		padding: 20,
	},
}));
