import { CircleRounded } from "@mui/icons-material";
import { blue, green, orange, red, yellow } from "@mui/material/colors";
import moment from "moment";

function quinaDisponibilitat(data, calendari) {
	const exist = calendari?.filter(
		(item) =>
			moment(item.data).format("YYYY-MM-DD") ===
			moment(data).format("YYYY-MM-DD")
	)?.[0];

	let activat = 0;

	if (exist) {
		activat = exist.disponibilitat;
	}
	return activat;
}

function teDisponibilitat(data, calendari) {
	const exist = calendari?.filter(
		(item) =>
			moment(item.data).format("YYYY-MM-DD") ===
			moment(data).format("YYYY-MM-DD")
	)?.[0];

	let color = green[500];

	if (exist) {
		if (String(exist.disponibilitat) === "1") {
			color = blue[300];
		} else if (String(exist.disponibilitat) === "2") {
			color = yellow[600];
		} else if (String(exist.disponibilitat) === "3") {
			color = orange[700];
		} else if (String(exist.disponibilitat) === "4") {
			color = red[400];
		}
	}

	return <CircleRounded sx={{ width: 18, fill: color, zIndex: 0 }} />;
}

export { quinaDisponibilitat, teDisponibilitat };
