import { Download } from "@mui/icons-material";
import { Alert, Grid, Typography, useMediaQuery } from "@mui/material";
import { Box, Container } from "@mui/system";
import { useTranslation } from "react-i18next";
import { ReactComponent as LogoPendol } from "../../../../assets/img/logo-pendol.svg";
import CustomPublicButton from "../../../../components/CustomPublicButton";
import theme from "../../../../theme";

export default function QuiGestiona() {
	const matches = useMediaQuery("(min-width:960px)");
	const { t } = useTranslation();
	return (
		<Box my={10} mb={matches ? 30 : 5}>
			<Container>
				<Typography variant="h1">{t("Qui ho gestiona?")}</Typography>
				<Grid spacing={5} container mt={4}>
					<Grid item md={6}>
						<Typography variant="body1">
							{t(
								"Pèndol Guies de Muntanya és l’empresa responsable de la gestió, control i manteniment de les instal·lacions. S’autoritzarà l’ús de les instal·lacions a terceres empreses, que compleixin la normativa establerta. És obligatori respectar la normativa d’ús. En cas contrari, es declinen totes les responsabilitats per danys i perjudicis, i es prendran les mesures legals pertinents. Si us plau, feu un ús responsable de les instal·lacions i ajudeu-nos a mantenir l’entorn"
							)}
						</Typography>
					</Grid>
					<Grid
						item
						md={6}
						onClick={() =>
							window.open("https://pendolguies.com", "_blank")
						}
					>
						<LogoPendol />
					</Grid>
				</Grid>
				<Box mt={4}>
					<Alert severity="warning">
						<Typography variant="h3" mb={3}>
							{t("Advertències usuaris/es")}
						</Typography>
						<Typography variant="body2">
							{t(
								"- Hi ha un risc mortal en la realització d’aquestes activitats sense les precaucions oportunes."
							)}
						</Typography>
						<Typography variant="body2">
							{t(
								"- En cas que l’usuari/ària no disposi dels coneixements tècnics i de l’experiència necessària, es recomana la contractació de "
							)}
							<span
								onClick={() =>
									window.open(
										"https://pendolguies.com",
										"_blank"
									)
								}
								style={{
									color: theme.palette.background.third,
									cursor: "pointer",
								}}
							>
								{t("guies especialitzats")}
							</span>
						</Typography>

						<Typography variant="body2">
							{t(
								"- Per a menors d’edat, cal autorització expressa del pare, mare o tutor legal."
							)}
						</Typography>

						<Typography variant="body2">
							{t(
								"- En cas de meteorologia adversa (tempesta, forts vents, etc.) no es permet fer ús de les instal·lacions."
							)}
						</Typography>

						<Typography variant="body2">
							{t(
								"- En cas de dubtes, disposeu de la normativa de l’Espai Actiu Vallcebre"
							)}
						</Typography>
					</Alert>
				</Box>
				<Box mt={4} textAlign="center">
					<CustomPublicButton
						title={t("NORMATIVA")}
						href={require("../../../../assets/docs/normativa.pdf")}
						Icon={Download}
						target="_blank"
					/>
				</Box>
			</Container>
		</Box>
	);
}
