import * as React from 'react';
import { Box, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Plus } from 'react-feather';

export default function IconList({ label, icon, pb }) {
    const classes = useStyles();
    if (label !== 'null')
        return (
            <Box display="flex" pt={pb}>
                <Box className={classes.icon}>{icon}</Box>

                <Typography variant="body1">{label}</Typography>
            </Box>
        );
}

const useStyles = makeStyles((theme) => ({
    icon: {
        marginRight: 10,
    },
}));
