import { Box } from "@mui/system";
import { useState } from "react";
import { PhotoAlbum } from "react-photo-album";
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";
import { Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

export function Album({ photos }) {
	const [index, setIndex] = useState(-1);
	const slides = photos?.map(({ src, key, width, height, images }) => ({
		src,
		key,
		width,
		height,
		srcSet: images?.map((image) => ({
			src: image.src,
			width: image.width / 4,
			height: image.height / 4,
		})),
	}));
	const { t } = useTranslation();

	return (
		<Box mt={10}>
			<Typography variant="h2" textAlign={"center"} mb={5}>
				{t("Fotos")}
			</Typography>
			<PhotoAlbum
				layout="rows"
				photos={photos}
				targetRowHeight={150}
				onClick={({ index }) => setIndex(index)}
			/>

			<Lightbox
				open={index >= 0}
				index={index}
				close={() => setIndex(-1)}
				slides={slides}
			/>
		</Box>
	);
}
