import { Tooltip, Typography, useMediaQuery } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Box } from "@mui/system";
import theme from "../../../../theme";

const Icons = ({ Icon, value, nom, append, big }) => {
	const classes = useStyles();
	const matches = useMediaQuery("(min-width:960px)");
	return (
		value && (
			<Box display={"flex"} flexDirection="column" alignItems={"center"}>
				<Tooltip title={nom} placement="top">
					<Box
						className={
							big === matches
								? classes.iconWrapBig
								: classes.iconWrap
						}
						mb={1}
					>
						<Icon
							className={big ? classes.iconBig : classes.icon}
						/>
					</Box>
				</Tooltip>
				<Typography
					variant={big === matches ? "h3" : "h6"}
					color={theme.palette.icon.wrap}
				>
					{value}
					{append}
				</Typography>
			</Box>
		)
	);
};

export default Icons;

const useStyles = makeStyles((theme) => ({
	icon: {
		width: 40,
		fill: theme.palette.icon.main,
	},
	iconBig: {
		width: 60,
		fill: theme.palette.icon.main,
	},
	iconWrap: {
		width: 30,
		height: 30,
		padding: 10,
		backgroundColor: theme.palette.icon.wrap,
		borderRadius: 40,
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
	},
	iconWrapBig: {
		width: 60,
		height: 60,
		padding: 10,
		backgroundColor: theme.palette.icon.wrap,
		borderRadius: 40,
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
	},
}));
