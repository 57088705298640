import { blue, green, orange, yellow, red } from "@mui/material/colors";

const dies = [
	{
		id: 0,
		text: "Obert jornada completa (09:00 a 17:00h)",
		color: green[500],
	},
	{
		id: 1,
		text: "Obert mitja jornada (10:00 a 15:00h)",
		color: blue[300],
	},
	{
		id: 2,
		text: "Tancat (obrim amb reserva prèvia)",
		color: yellow[600],
	},
	{
		id: 3,
		text: "A determinar obertura en funció de la meteorologia (mitja jornada)",
		color: orange[700],
	},
	{
		id: 4,
		text: "Manteniment espais (tancament total)",
		color: red[400],
	},
];

export default dies;
