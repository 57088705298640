import { Box, Container } from "@mui/material";
import { makeStyles, ThemeProvider } from "@mui/styles";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import Title from "../../../components/Layout/Title";
import MUIDataTable from "mui-datatables";
import TableOptions from "../../../components/TableOptions";
import { getElementsAdmin } from "../../../database/API";
import { Plus, RefreshCw } from "react-feather";
import getMuiTheme from "../../../theme/TableStyle";
import Loading from "../../../components/Layout/Loading";
import CustomButton from "../../../components/CustomButton";
import { useSnackbar } from "notistack";
import EntradesColumns from "./EntradesColumn";

const useStyles = makeStyles((theme) => ({
	container: {
		paddingBottom: 80,
	},
	"@global": {
		html: {
			width: "100%",
			height: "100%",
		},
	},
}));

const EntradesAdmin = () => {
	const classes = useStyles();
	const [entrades, setEntrades] = useState([]);
	const [loading, setLoading] = useState(true);
	const filterList = JSON.parse(localStorage.getItem("reservesFilter"));
	const columns = EntradesColumns(entrades, filterList ? filterList : []);
	const navigate = useNavigate();
	const { enqueueSnackbar } = useSnackbar();

	useEffect(() => {
		const get = async () => {
			const { result } = await getElementsAdmin("entrades");
			setEntrades(result);
			setLoading(false);
		};

		get();
	}, []);

	const filterFunc = (changedColumn, filterList) =>
		localStorage.setItem("reservesFilter", JSON.stringify(filterList[2]));

	const refresca = () => {
		const llargada = entrades?.length;
		setLoading(true);
		const get = async () => {
			const { result } = await getElementsAdmin("entrades");
			console.log(result);
			setEntrades(result);
			setLoading(false);
			const llargada_nova = result?.length;
			if (llargada_nova - llargada === 0) {
				enqueueSnackbar("No hi ha noves comandes", {
					variant: "success",
				});
			} else {
				enqueueSnackbar(
					"S'ha afegit " + (llargada_nova - llargada) + " comanda/es",
					{
						variant: "success",
					}
				);
			}
		};
		get();
	};

	const CustomToolbar = () => {
		return (
			<CustomButton
				onClick={refresca}
				style={{ marginLeft: 10 }}
				title={
					<>
						<RefreshCw size={15} /> Refresca
					</>
				}
			/>
		);
	};
	const options = TableOptions(CustomToolbar, filterFunc);

	return (
		<Box className={classes.container}>
			<Title title="Entrades" />
			<Container maxWidth="xl">
				<Loading loading={loading}>
					<Box pt={4} spacing={3}>
						<ThemeProvider theme={getMuiTheme()}>
							<MUIDataTable
								data={entrades}
								columns={columns}
								options={options}
							/>
						</ThemeProvider>
					</Box>
				</Loading>
			</Container>
		</Box>
	);
};

export default EntradesAdmin;
