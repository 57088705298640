import {
	Avatar,
	Box,
	Container,
	Grid,
	Table,
	TableBody,
	TableCell,
	TableRow,
	Typography,
	useMediaQuery,
} from "@mui/material";
import React from "react";
import Header from "../../../components/Header";
import Page from "../../../components/Layout/Page";
import Sanefa from "../../../components/Sanefa";
import img from "../../../assets/img/espais/escalada.jpg";
import { makeStyles } from "@mui/styles";
import { useTranslation } from "react-i18next";
import { ReactComponent as Icon } from "../../../assets/logo.svg";
import usePageTracking from "../../../layouts/TrakingGA";
import theme from "../../../theme";
import CalendariDisponibilitat from "../../../components/CalendariDisponibilitat";
import moment from "moment";
import dies from "../../../data/TipusDies";
import { CircleRounded } from "@mui/icons-material";

const Horaris = () => {
	const classes = useStyles();
	const matches = useMediaQuery("(min-width:960px)");
	const { t } = useTranslation();
	usePageTracking();

	return (
		<Page
			title="Espai Actiu Vallcebre - Horaris i tarifes"
			className={classes.main}
		>
			<Header title={t("Horaris i tarifes")} img={img} />
			<Sanefa color="white" />
			<Container>
				<Box textAlign="center" className={classes.section}>
					<Box mb={7}>
						<Typography variant="h2" mb={5}>
							{t("Tarifes")} {moment().format("YYYY")}
						</Typography>
						<Typography variant="h3">
							{t("FORFAIT ACCÉS GENERAL")}
						</Typography>
						<Typography variant="body1">
							{t("Assegurança inclosa dins del forfait d’accés.")}
						</Typography>
						<Typography variant="caption">
							{t(
								"(DRET D’ÚS PER 1 DIA D’EQUIPAMENTS ESPORTIUS EN VIES FERRADES, ESCOLA D’ESCALADA I BARRANC)"
							)}
						</Typography>
						<Table>
							<TableBody>
								<TableRow>
									<TableCell>{t("INDIVIDUAL")}</TableCell>
									<TableCell>5 €</TableCell>
								</TableRow>
								<TableRow>
									<TableCell>
										{t("INDIVIDUAL FEDERAT")}
									</TableCell>
									<TableCell>3 €</TableCell>
								</TableRow>
								<TableRow>
									<TableCell>
										{t("MENORS DE 10 ANYS")}
									</TableCell>
									<TableCell>3 €</TableCell>
								</TableRow>
							</TableBody>
						</Table>
						<Typography variant="caption">
							{t(
								"Les persones federades hauran de presentar el carnet corresponent vigent."
							)}
						</Typography>
					</Box>
					<Box mb={7}>
						<Typography variant="h3">
							{t("FORFAIT ACCÉS BARRANCS")}
						</Typography>
						<Typography variant="body1">
							{t("Assegurança inclosa dins del forfait d’accés.")}
						</Typography>
						<Typography variant="caption">
							{t(
								"(DRET D’ÚS PER 1 DIA D’EQUIPAMENTS ESPORTIUS EN BARRANCS)"
							)}
						</Typography>
						<Table>
							<TableBody>
								<TableRow>
									<TableCell>{t("INDIVIDUAL")}</TableCell>
									<TableCell>3,5 €</TableCell>
								</TableRow>
								<TableRow>
									<TableCell>
										{t("INDIVIDUAL FEDERAT")}
									</TableCell>
									<TableCell>2 €</TableCell>
								</TableRow>
								<TableRow>
									<TableCell>
										{t("MENORS DE 10 ANYS")}
									</TableCell>
									<TableCell>2 €</TableCell>
								</TableRow>
							</TableBody>
						</Table>
						<Typography variant="caption">
							{t(
								"Les persones federades hauran de presentar el carnet corresponent vigent."
							)}
						</Typography>
					</Box>

					<Typography variant="h2" mb={5}>
						{t("Horaris i disponibilitat")}{" "}
						{moment().format("YYYY")}
					</Typography>
					<Container>
						<Box
							display="flex"
							mb={5}
							justifyContent={"space-between"}
						>
							{dies.map((item) => (
								<Box
									key={item.id}
									display="flex"
									alignItems={"center"}
								>
									<CircleRounded
										sx={{
											width: 18,
											fill: item.color,
											zIndex: 0,
											marginRight: 1,
										}}
									/>
									<Typography variant="caption">
										{item.text}
									</Typography>
								</Box>
							))}
						</Box>
					</Container>
					<CalendariDisponibilitat />
				</Box>
			</Container>

			<Sanefa color="white" />
		</Page>
	);
};

const useStyles = makeStyles((theme) => ({
	logo: {
		width: 300,
		marginBottom: 120,
		fill: "white",
	},
	logoMobile: {
		width: 150,
		fill: "white",
	},
	section: {
		paddingBottom: 300,
		paddingTop: 100,
	},
	missio: {
		paddingTop: 100,
		paddingBottom: 200,
		textAlign: "center",
		background: theme.palette.background.gradient,
		marginTop: -5,
	},
}));

export default Horaris;
