import React from "react";
import clsx from "clsx";
import { makeStyles } from "@mui/styles";
import { Box, Typography } from "@mui/material";

const useStyles = makeStyles((theme) => ({
	icon: { padding: 5 },
	badge: {
		paddingTop: 5,
		paddingBottom: 5,
		paddingRight: 10,
		paddingLeft: 10,
		borderRadius: 15,
		color: "white",
		textAlign: "center",
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		transition: "0.2s",
	},
	espera: {
		backgroundColor: "#ababab",
	},
	completada: {
		backgroundColor: "#8bc34a",
	},
	cancelada: {
		backgroundColor: "#ff1744",
	},
	pagada: {
		backgroundColor: "#03a9f4",
	},
}));

const StateColumn = (props) => {
	const classes = useStyles();
	const { state } = props;

	const style =
		state.nom === "Pagat"
			? classes.pagada
			: state.nom === "Completat"
			? classes.completada
			: state.nom === "En espera"
			? classes.espera
			: classes.cancelada;

	return (
		<Box className={clsx(classes.badge, style)}>
			<Typography style={{ color: "white", fontSize: 12 }}>
				{state.nom}
			</Typography>
		</Box>
	);
};

export default StateColumn;
