import { Add } from "@mui/icons-material";
import { Chip, IconButton, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Box } from "@mui/system";
import { Delete } from "react-feather";
import { useTranslation } from "react-i18next";

const EntradesPersones = ({ personesForm, setOpen, watch, errors }) => {
	const classes = useStyles();
	const { t } = useTranslation();

	const renderFederat = (val) => {
		let res;
		switch (val) {
			case "federat":
				res = "Federat";
				break;
			case "no_federat":
				res = "No federat";
				break;
			case "nen":
				res = "Menor";
				break;
			default:
				break;
		}
		return res;
	};
	return (
		<Box
			className={
				errors?.persones ? classes.blockError : classes.blockDate
			}
		>
			<Typography variant="h6" textTransform="uppercase" pl={2}>
				{t("Persones")}
			</Typography>
			{errors?.persones && (
				<Typography variant="error" textAlign={"center"} p={2}>
					{errors?.persones?.message}
				</Typography>
			)}
			<Box textAlign={"center"} mb={1}>
				{personesForm.fields.map((item, index) => {
					return (
						<Box key={item.id} className={classes.blockPersona}>
							<Box display={"flex"}>
								<Chip
									color="warning"
									label={renderFederat(
										watch(`persones.${index}.federat`)
									)}
								/>
								<Box textAlign={"left"} ml={1}>
									<Typography fontSize={12} color="white">
										{watch(`persones.${index}.nom`)}
									</Typography>
									<Typography variant="caption" color="white">
										{watch(`persones.${index}.dni`)}
									</Typography>
								</Box>
							</Box>
							<IconButton
								onClick={() => personesForm.remove(index)}
							>
								<Delete color="white" />
							</IconButton>
						</Box>
					);
				})}
				<IconButton onClick={() => setOpen(true)}>
					<Add />
				</IconButton>
			</Box>
		</Box>
	);
};

export default EntradesPersones;

const useStyles = makeStyles((theme) => ({
	blockDate: {
		border: "1px solid " + theme.palette.background.third,
		borderRadius: 5,
		paddingTop: 20,
		marginBottom: 20,
	},
	blockError: {
		border: "1px solid red",
		borderRadius: 5,
		paddingTop: 20,
		marginBottom: 20,
	},
	blockPersona: {
		padding: 10,
		backgroundColor: "#b5d558",
		borderRadius: 10,
		display: "flex",
		margin: 10,
		justifyContent: "space-between",
		alignContent: "center",
	},
}));
