import { Circle } from "@mui/icons-material";
import { Grid, Typography, useMediaQuery } from "@mui/material";
import { Box, Container } from "@mui/system";
import { useTranslation } from "react-i18next";
import theme from "../../../../theme";

export default function Serveis() {
	const matches = useMediaQuery("(min-width:960px)");
	const { t } = useTranslation();
	return (
		<Box my={10} mb={matches ? 30 : 10}>
			<Container>
				<Typography variant="h1">{t("Serveis")}</Typography>
				<Typography variant="body1">
					{t("En horari d'obertura")}
				</Typography>
				<Grid spacing={5} container mt={1}>
					<Grid item md={6}>
						<Typography variant="h3" mb={3}>
							{t("Lloguer de material")}
						</Typography>
						<Typography variant="h3">
							{t("Guiatges per particulars, empreses i escoles")}
						</Typography>
					</Grid>
					<Grid item md={6}>
						<Typography variant="h3" mb={3}>
							{t("Punt informació turístic")}
						</Typography>
						<Typography variant="h3">
							{t("Lavabo WC ecològic")}
						</Typography>
					</Grid>
				</Grid>
			</Container>
		</Box>
	);
}
