import { Box } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { ReactComponent as San } from "../assets/img/canvi.svg";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
	wraper: {
		position: "relative",
		marginTop: "-9%",
	},
	inverted: {
		transform: "rotate(180deg)",
		// backgroundColor: theme.palette.background.main,
		zIndex: -1,
		marginTop: -1,
		"& svg": {
			marginBottom: -10,
		},
	},

	fosc: {
		transform: "rotate(180deg)",
		position: "relative",
		backgroundColor: theme.palette.background.secondary,
		zIndex: -1,
		"& svg": {
			marginBottom: -10,
		},
	},
}));

export default function Sanefa({ color, inverted, fosc }) {
	const classes = useStyles();
	return (
		<Box
			className={
				inverted
					? classes.inverted
					: fosc
					? classes.fosc
					: classes.wraper
			}
		>
			<San fill={color} />
		</Box>
	);
}
