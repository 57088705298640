import { Circle } from "@mui/icons-material";
import { Grid, Typography } from "@mui/material";
import { Box, Container } from "@mui/system";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import theme from "../../../../theme";

export default function QueCal() {
	const { t } = useTranslation();
	const navigate = useNavigate();
	return (
		<Box backgroundColor={theme.palette.background.third} mt={-1} py={10}>
			<Container>
				<Typography variant="h1" color="white">
					{t("Què cal per accedir?")}
				</Typography>
				<Typography variant="body1" color="white" mt={2}>
					{t(
						"Per accedir i utilitzar les instal·lacions de pagament (vies ferrades, escola d’escalada i Barranquisme) cal tenir el forfait."
					)}
				</Typography>
				<Grid spacing={5} container mt={2}>
					<Grid item md={6}>
						<Box display={"flex"} alignItems={"flex-end"}>
							<Typography variant="h1" color="white" mr={1}>
								1
							</Typography>
							<Typography color="white" variant="h3" mb={1}>
								{t("PAGAR FORFAIT ENTRADA")}
							</Typography>
						</Box>
						<Typography color="white">
							{t(
								"Presencialment: Caseta Pla de la Barraca o Oficina Turisme Vallcebre (Alberg) 622 766 152 (en horari d’atenció al públic; consulteu calendari)."
							)}
						</Typography>
						<Typography color="black">
							<span
								style={{ cursor: "pointer" }}
								onClick={() => navigate("/entrades")}
							>
								{t("Online: A través d’aquesta pagina web")}
							</span>
						</Typography>
					</Grid>
					<Grid item md={6}>
						<Box display={"flex"} alignItems={"flex-end"}>
							<Typography variant="h1" color="white" mr={1}>
								2
							</Typography>
							<Typography color="white" variant="h3" mb={1}>
								{t("ASSEGURANÇA")}
							</Typography>
						</Box>
						<Typography color="white" variant="body2">
							{t("Inclosa en el forfait d'accés")}
						</Typography>
						<Typography color="white">
							{t(
								"Persones amb llicència federativa pertinent, estaran exemptes de pagament de l’assegurança"
							)}
						</Typography>
					</Grid>
				</Grid>
				<Grid spacing={5} container mt={2}>
					<Grid item md={6}>
						<Box display={"flex"} alignItems={"flex-end"}>
							<Typography variant="h1" color="white" mr={1}>
								3
							</Typography>
							<Typography color="white" variant="h3" mb={1}>
								{t("MATERIAL OBLIGATORI")}
							</Typography>
						</Box>
						<Typography color="white" variant="body2">
							{t("Consulteu ressenyes de les instal·lacions")}
						</Typography>
						<Typography color="white">
							{t(
								"Cal disposar del material i equipament mínim definit per a cada instal·lació, en bon estat de conservació i segons normativa vigent."
							)}
						</Typography>
						<Typography color="white">
							{t("Disposeu de l’opció de")}{" "}
							<span
								onClick={() =>
									window.open(
										"https://pendolguies.com/lloguer",
										"_blank"
									)
								}
								style={{
									color: "black",
									cursor: "pointer",
								}}
							>
								{t("lloguer de material")}
							</span>{" "}
							{t(
								"tant de ferrada com de barranquisme que aconsellem reservar prèviament per email o telèfon."
							)}
						</Typography>
					</Grid>
					<Grid item md={6}>
						<Box display={"flex"} alignItems={"flex-end"}>
							<Typography variant="h1" color="white" mr={1}>
								4
							</Typography>
							<Typography color="white" variant="h3" mb={1}>
								{t(
									"SEGUIR LA NORMATIVA ESPECÍFICA DE CADA INSTAL·LACIÓ"
								)}
							</Typography>
						</Box>
						<Typography color="white" variant="body2">
							{t("Consulteu ressenyes de les instal·lacions")}
						</Typography>
						<Typography color="white">
							{t(
								"Per qualsevol consulta, podeu posar-vos en contacte amb Pèndol Guies de Muntanya en horari laboral al 622 766 152 o bé escriure un mail a info@espaiactiuvallcebre.cat"
							)}
						</Typography>
					</Grid>
				</Grid>
			</Container>
		</Box>
	);
}
