import { Box } from "@mui/material";
import { makeStyles } from "@mui/styles";
import moment from "moment";
import { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import { teDisponibilitat } from "../data/Utils";
import { getElements } from "../database/API";
import i18n from "../lang";
import { getDate } from "date-fns";
import LoadingOverlay from "./Layout/LoadingOverlay";

export default function CalendariDisponibilitat() {
	const [loading, setLoading] = useState(true);
	const [calendari, setCalendari] = useState([]);
	const classes = useStyles();

	useEffect(() => {
		const get = async () => {
			setLoading(true);
			const calendaris = await getElements("calendaris");
			setCalendari(calendaris.result);
			setTimeout(() => setLoading(false), 500);
		};

		get();
	}, []);

	const renderDia = (day, date) => {
		return (
			<Box style={{ position: "relative" }}>
				<Box height={22}>{getDate(date)}</Box>
				<Box className={classes.iconCalendar}>
					{teDisponibilitat(moment(date), calendari)}
				</Box>
			</Box>
		);
	};

	return (
		<LoadingOverlay loading={loading}>
			<DatePicker
				// selected={moment().toDate()}
				dateFormat="dd/MM/yyyy"
				disabled
				minDate={moment("01-01-" + moment().format("YYYY")).toDate()}
				maxDate={moment("01-01-" + moment().format("YYYY")).toDate()}
				renderDayContents={renderDia}
				inline
				locale={i18n.language}
				monthsShown={12}
				disabledKeyboardNavigation
			/>
		</LoadingOverlay>
	);
}

const useStyles = makeStyles((theme) => ({
	iconCalendar: {
		display: "flex",
		justifyContent: "center",
		alignItems: "flex-start",
	},
}));
