import * as React from "react";
import { FormControl, InputLabel, Select } from "@mui/material";

export default function CustomSelect({
	register,
	name,
	label,
	elements,
	defecte,
}) {
	return (
		<FormControl fullWidth>
			<InputLabel shrink={true} htmlFor={name}>
				{label}
			</InputLabel>
			<Select
				native
				label={label}
				name={name}
				{...register(name)}
				notched={true}
			>
				{defecte && <option value=""></option>}
				{elements?.map((item) => (
					<option value={item.id}>{item.nom}</option>
				))}
			</Select>
		</FormControl>
	);
}
