import { Add } from "@mui/icons-material";
import {
	Button,
	Collapse,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	FormControl,
	FormControlLabel,
	Radio,
	RadioGroup,
	TextField,
	Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Box } from "@mui/system";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

const EntradesDialogAddPersona = ({ open, setOpen, personesForm }) => {
	const [radio, setRadio] = useState();
	const [nom, setNom] = useState();
	const [dni, setDni] = useState();
	const [carnet, setCarnet] = useState();
	const [error, setError] = useState(false);
	const [errorMsg, setErrorMsg] = useState("");
	const classes = useStyles();
	const { t } = useTranslation();

	const handleAdd = async () => {
		if (nom && radio) {
			if (radio !== "nen") {
				if (dni) {
					if (radio === "federat") {
						await validateSelectedFile(carnet);
						if (errorMsg === "") {
							if (carnet) {
								personesForm.append({
									nom: nom,
									dni: dni,
									federat: radio,
									carnet: carnet,
								});
								setRadio("");
								setNom("");
								setDni("");
								setCarnet("");
								setOpen(false);
								setError(false);
							} else {
								setError(true);
							}
						} else {
							setError(true);
						}
					} else {
						personesForm.append({
							nom: nom,
							dni: dni,
							federat: radio,
						});
						setRadio("");
						setNom("");
						setDni("");
						setOpen(false);
						setError(false);
					}
				} else {
					setError(true);
				}
			} else {
				personesForm.append({ nom: nom, federat: radio });
				setRadio("");
				setNom("");
				setDni("");
				setOpen(false);
				setError(false);
			}
		} else {
			setError(true);
		}
	};

	const validateSelectedFile = (file) => {
		const MAX_FILE_SIZE = 4096; // 5MB

		const fileSizeKiloBytes = file[0].size / 1024;

		if (fileSizeKiloBytes > MAX_FILE_SIZE) {
			setErrorMsg(
				"La fotografia supera els 4MB. Penja un fotografia de menys de 4MB."
			);
			return;
		}

		setErrorMsg("");
	};

	const handleClose = () => {
		setOpen(false);
		setError(false);
	};

	return (
		<Dialog
			open={open}
			onClose={() => setOpen(false)}
			aria-labelledby="alert-dialog-title"
			aria-describedby="alert-dialog-description"
		>
			<DialogTitle>{t("Afegir persona")}</DialogTitle>
			<DialogContent>
				<Box textAlign={"center"}>
					<FormControl component="fieldset">
						<RadioGroup
							row
							value={radio}
							onChange={(event) => setRadio(event.target.value)}
						>
							<FormControlLabel
								value="federat"
								control={<Radio />}
								label={t("Federat")}
							/>
							<FormControlLabel
								value="no_federat"
								control={<Radio />}
								label={t("No federat")}
							/>
							<FormControlLabel
								value="nen"
								control={<Radio />}
								label={t("Menor de 10 anys")}
							/>
						</RadioGroup>
					</FormControl>
					<Box mb={2}>
						<TextField
							size="small"
							label={t("Nom i cognoms")}
							name="nom"
							value={nom}
							fullWidth
							onChange={(event) => setNom(event.target.value)}
							required
						/>
					</Box>
					<Box mb={2}>
						<Collapse in={radio !== "nen"}>
							<TextField
								size="small"
								name="dni"
								label="DNI"
								value={dni}
								fullWidth
								onChange={(event) => setDni(event.target.value)}
								required={radio !== "nen"}
							/>
						</Collapse>
					</Box>
					<Box textAlign={"left"}>
						<Collapse in={radio === "federat"}>
							<Typography variant={"body1"} mb={1}>
								{t("Carnet FEEC")} *{" "}
								{carnet && "(" + carnet[0]?.name + ")"}
							</Typography>
							<label>
								<input
									name={"carnet"}
									onChange={(e) => {
										setCarnet([...e.target.files]);
										validateSelectedFile([
											...e.target.files,
										]);
									}}
									type="file"
									style={{
										display: "none",
									}}
									required={radio === "federat"}
								/>
								<Box className={classes.boto}>
									<Add />
									<Typography color="white">
										{carnet
											? t("Modificar fotografia")
											: t("Afegir fotografia")}
									</Typography>
								</Box>
								<Typography variant="caption">
									{t(
										"Per a poder validar que aquesta persona és federada, s'ha de penjar una fotografia del carnet de federat o un document que acrediti una assegurança pel dia seleccionat. Si el document no és vàlid, el dia que accedeixi a l'espai, s'haurà d'abonar la diferència."
									)}
								</Typography>
							</label>
						</Collapse>
					</Box>
				</Box>
				{error && (
					<Typography variant="error">
						{t("Falten camps per omplir")}
					</Typography>
				)}
				<br />
				{errorMsg && (
					<Typography variant="error">{t(errorMsg)}</Typography>
				)}
			</DialogContent>
			<DialogActions>
				<Button color="danger" onClick={handleClose}>
					{t("Tancar")}
				</Button>
				<Button onClick={handleAdd}>{t("Afegir")}</Button>
			</DialogActions>
		</Dialog>
	);
};

export default EntradesDialogAddPersona;
const useStyles = makeStyles((theme) => ({
	boto: {
		boxShadow: "none",
		textTransform: "none",
		padding: "10px 20px",
		borderRadius: 5,
		lineHeight: 1.5,
		color: "white",
		backgroundColor: theme.palette.background.main,
		borderColor: theme.palette.background.main,
		fontFamily: "Open Sans",
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		cursor: "pointer",
	},
}));
