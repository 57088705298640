import React from "react";
import { Box, Container, Grid, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import { ReactComponent as Acuatic } from "../../../../assets/img/icons/acuatic.svg";
import { ReactComponent as Barranquisme } from "../../../../assets/img/icons/barranquisme.svg";
import { ReactComponent as Escalada } from "../../../../assets/img/icons/escalada.svg";
import { ReactComponent as Ruta } from "../../../../assets/img/icons/ruta.svg";
import { ReactComponent as Via } from "../../../../assets/img/icons/via.svg";

export default function Espais() {
	const classes = useStyles();
	const { t } = useTranslation();

	return (
		<Box className={classes.container}>
			<Container maxWidth="lg">
				<Typography
					variant="h1"
					textAlign="center"
					marginBottom={8}
					color="white"
				>
					{t("Espais")}
				</Typography>
				<Grid container spacing={8}>
					<Espai nom="Vies Ferrates" Icon={Via} cat={1} />
					<Espai nom="Barranquisme" Icon={Barranquisme} cat={2} />
					<Espai nom="Acuàtic" Icon={Acuatic} cat={5} />
					<Espai nom="Escalada" Icon={Escalada} cat={4} />
					<Espai nom="Rutes" Icon={Ruta} cat={3} />
				</Grid>
			</Container>
		</Box>
	);
}

const Espai = ({ nom, Icon, cat }) => {
	const classes = useStyles();
	let navigate = useNavigate();
	const { t } = useTranslation();
	return (
		<Grid item md={2.4} xs={12}>
			<Box
				onClick={() =>
					navigate("/espais", {
						state: cat,
					})
				}
				className={classes.wrap}
			>
				<Icon style={{ width: 100, height: 100 }} />
				<Typography variant="h3" textTransform="uppercase" padding={1}>
					{t(nom)}
				</Typography>
			</Box>
		</Grid>
	);
};

const useStyles = makeStyles((theme) => ({
	container: {
		paddingTop: 100,
		paddingBottom: 120,
		textAlign: "center",
		backgroundColor: theme.palette.background.third,
		marginTop: -5,
		marginBottom: -5,
		zIndex: 10,
	},
	wrap: {
		paddingTop: 10,
		backgroundColor: "white",
		border: "2px solid white",
		borderRadius: 15,
		cursor: "pointer",
		overflow: "hidden",
		transition: "0.2s",
		"&:hover": {
			transform: "scale(1.05)",
			boxShadow: "0px 0px 5px 0px " + theme.palette.background.main,
			backgroundColor: theme.palette.background.main,
			"& .MuiTypography-root": {
				transition: "0.2s",
				color: "white",
			},
			"& svg": {
				transition: "0.2s",
				fill: "white",
			},
		},
	},
	img: {
		width: "100%",
	},
	imgWrap: {
		height: 200,
		overflow: "hidden",
	},
}));
