import { makeStyles } from '@mui/styles';
// import logo from '../../assets/img/fons.jpg';

const HomeStyle = makeStyles((theme) => ({
    main: {
        marginBottom: 0,
    },
    root: {
        paddingTop: 150,
        paddingBottom: 300,
        backgroundSize: 'cover',
        overflow: 'auto',
        justifyContent: 'center',
    },
    reserva: {
        boxShadow: '#00000020 1px 3px 20px 1px ',
        borderRadius: 30,
        padding: 30,
        zIndex: 0,
        marginTop: 80,
        backgroundColor: 'white',
        overflow: 'hidden',
        position: 'relative',
    },
    first: {
        marginTop: -200,
    },
    dates: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        border: '1px solid black',
        borderRadius: 4,
    },
    fons: {
        position: 'absolute',
        top: 0,
        left: 0,
        zIndex: 0,
    },
    icon: {
        width: 35,
        height: 35,
        justifyContent: 'center',
        alignItems: 'center',
        display: 'flex',
    },
    form: {
        display: 'flex',
        justifyContent: 'space-between',
        flexDirection: 'column',
        height: '100%',
    },
    continuar: {
        display: 'flex',
        justifyContent: 'flex-end',
        marginTop: -25,
        marginRight: 50,
    },
    datesCurt: {
        position: 'absolute',
        display: 'flex',
        flexDirection: 'column',
        left: 0,
        right: 0,
        paddingRight: 30,
        paddingLeft: 30,
    },
    inputImage: {
        fontSize: 16,
        padding: 10,
        borderRadius: 15,
        color: 'white',
        backgroundColor: '#424242',
        borderColor: '#424242',
        fontFamily: 'Open Sans',
    },
    boto: {
        boxShadow: 'none',
        textTransform: 'none',
        fontSize: 14,
        padding: '10px 20px',
        borderRadius: 15,
        lineHeight: 1.5,
        color: 'white',
        backgroundColor: '#424242',
        borderColor: '#424242',
        fontFamily: 'Open Sans',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        cursor: 'pointer',
    },
    titol: {
        color: 'green',
        display: 'flex',
        justifyContent: 'center',
    },
    resum: {
        paddingTop: 40,
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
    },
    loading: {
        position: 'fixed',
        zIndex: 10,
        height: '100%',
        alignItems: 'center',
        alignContent: 'center',
        justifyContent: 'center',
        display: 'flex',
        width: '100%',
        backgroundColor: '#ffffff90',
    },
    text: {
        paddingTop: 25,
        fontSize: '18px !important',
    },
    extra: {
        lineHeight: '10px',
        marginRight: 35,
        '& p': {
            lineHeight: '16px',
        },
    },
    extraBox: {
        boxShadow: '#00000020 1px 3px 20px 1px ',
        borderRadius: 25,
        padding: 15,
    },
    counters: {
        display: 'flex',
        justifyContent: 'center',
    },
    block: {
        boxShadow: '#00000020 1px 3px 20px 1px ',
        borderRadius: 30,
        zIndex: 0,
        marginTop: 10,
        marginBottom: 0,
        backgroundColor: 'white',
        overflow: 'hidden',
        position: 'relative',
    },
    blockXec: {
        boxShadow: '#00000020 1px 3px 20px 1px ',
        borderRadius: 30,
        zIndex: 0,
        marginTop: 10,
        marginBottom: 0,
        padding: 25,
        backgroundColor: 'white',
        overflow: 'hidden',
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
    },
    list: {
        paddingLeft: 20,
    },
    persona: {
        fontWeight: 700,
        fontFamily: 'Cookie !important',
        fontSize: '35px !important',
    },
}));

export default HomeStyle;
