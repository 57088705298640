import * as React from "react";
import { Box, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import clsx from "clsx";
import { useTranslation } from "react-i18next";

export default function CategoriaFiltre({ cat, set, categoria }) {
	const classes = useStyles();
	const { t } = useTranslation();

	if (cat) {
		return (
			<Box
				onClick={() => set(cat.id)}
				className={clsx(
					classes.chip,
					cat.id === categoria ? classes.active : ""
				)}
			>
				<Typography
					variant="body1"
					textTransform={"uppercase"}
					color={cat.id === categoria ? "white" : ""}
				>
					{t(cat.nom)}
				</Typography>
			</Box>
		);
	} else {
		return (
			<Box
				onClick={() => set("")}
				className={clsx(
					classes.chip,
					"" === categoria ? classes.active : ""
				)}
			>
				<Typography
					variant="body1"
					textTransform={"uppercase"}
					color={"" === categoria ? "white" : ""}
				>
					{t("Totes")}
				</Typography>
			</Box>
		);
	}
}

const useStyles = makeStyles((theme) => ({
	chip: {
		paddingLeft: 15,
		paddingRight: 15,
		paddingTop: 5,
		paddingBottom: 5,
		margin: 5,
		borderRadius: 20,
		border: "1px solid " + theme.palette.background.main,
		cursor: "pointer",
		transition: "0.2s",
		display: "inline-block",
	},
	active: {
		backgroundColor: theme.palette.background.color,
	},
}));
