import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import detector from "i18next-browser-languagedetector";
import moment from "moment/min/moment-with-locales";
import TRANSLATIONS_CA from "./translations/ca.json";
import TRANSLATIONS_ES from "./translations/es.json";
import TRANSLATIONS_EN from "./translations/en.json";
import { setDefaultLocale } from "react-datepicker";

const resources = {
	ca: {
		translation: TRANSLATIONS_CA,
	},
	es: {
		translation: TRANSLATIONS_ES,
	},
	en: {
		translation: TRANSLATIONS_EN,
	},
	otherLanguages: "ca",
};

i18n.use(detector)
	.use(initReactI18next)
	.init({
		resources,
		lng: "ca",
		fallbackLng: "ca",
		keySeparator: false, // we do not use keys in form messages.welcome

		interpolation: {
			escapeValue: false, // react already safes from xss
		},
	});
i18n.moment = moment;
i18n.moment.locale(i18n.language);
setDefaultLocale("ca");
export default i18n;
