import axios from "axios";

const url = "https://pendolguies.covcontrol.net/public/api/";

const Login = async (email, password) => {
	let message;
	await axios
		.post(url + "login", {
			email: email,
			password: password,
			remember_me: true,
		})
		.then((response) => {
			if (response.status === 200) {
				localStorage.setItem("isLoggedIn", true);
				localStorage.setItem("user", JSON.stringify(response.data));
				message = "Login amb èxit";
			}
			if (
				response.data.status === "failed" &&
				response.data.success === undefined
			) {
				message = "error";
			}
		})
		.catch((error) => {
			message = "error";
			console.log(error);
		});

	return message;
};

const getUser = async () => {
	const user = JSON.parse(localStorage.getItem("user"));
	let message;
	let config = {
		headers: {
			Accept: "application/json",
			Authorization: "Bearer " + user?.token,
		},
	};
	await axios
		.get(url + "user", config)
		.then((response) => {
			if (response.status === 200) {
				message = response.status;
			}
			if (
				response.data.status === "failed" &&
				response.data.success === undefined
			) {
				message = "401";
			}
		})
		.catch((error) => {
			if (error.response.status === 401) {
				localStorage.removeItem("user");
			}
		});
	return { message };
};

const logout = async () => {
	const user = JSON.parse(localStorage.getItem("user"));
	let message;
	let get_user;
	let config = {
		headers: {
			Accept: "application/json",
			Authorization: "Bearer " + user?.token,
		},
	};
	await axios
		.get(url + "logout", config)
		.then((response) => {
			if (response.status === 200) {
				get_user = response.data;
				message = "400";
			}
			if (
				response.data.status === "failed" &&
				response.data.success === undefined
			) {
				message = "401";
			}
		})
		.catch((error) => {
			if (error.response.status === 401) {
				localStorage.removeItem("user");
				localStorage.setItem("isLoggedIn", false);
			}
		});
	return { get_user, message };
};

const getElements = async (element) => {
	let message;
	let result;
	let config = {
		headers: {
			"Access-Control-Allow-Origin": "*",
		},
	};
	await axios
		.get(url + element, config)
		.then((response) => {
			if (response.status === 200) {
				result = response.data.data;
				message = "Obtingut amb èxit";
			}
			if (
				response.data.status === "failed" &&
				response.data.success === undefined
			) {
				message = "No s'han trobat";
			}
		})
		.catch((error) => {
			console.log(error);
		});

	return { result, message };
};

const getElement = async (element, key) => {
	let message;
	let result;
	let config = {
		headers: {
			"Access-Control-Allow-Origin": "*",
		},
	};
	await axios
		.get(url + element + "/" + key, config)
		.then((response) => {
			if (response.status === 200) {
				result = response.data.data;
				message = "Obtingut amb èxit";
			}
			if (
				response.data.status === "failed" &&
				response.data.success === undefined
			) {
				message = "No s'han trobat";
			}
		})
		.catch((error) => {
			console.log(error);
		});

	return { result, message };
};

const getElementsAdmin = async (element) => {
	const user = JSON.parse(localStorage.getItem("user"));
	let message;
	let result;
	let config = {
		headers: {
			"Access-Control-Allow-Origin": "*",
			Authorization: "Bearer " + user?.token,
		},
	};
	await axios
		.get(url + element, config)
		.then((response) => {
			if (response.status === 200) {
				result = response.data.data;
				message = "Obtingut amb èxit";
			}
			if (
				response.data.status === "failed" &&
				response.data.success === undefined
			) {
				message = "No s'han trobat";
			}
		})
		.catch((error) => {
			console.log(error);
		});

	return { result, message };
};

const getElementAdmin = async (element, key) => {
	const user = JSON.parse(localStorage.getItem("user"));
	let message;
	let result;
	let config = {
		headers: {
			"Access-Control-Allow-Origin": "*",
			Authorization: "Bearer " + user?.token,
		},
	};
	await axios
		.get(url + element + "/" + key, config)
		.then((response) => {
			if (response.status === 200) {
				result = response.data.data;
				message = "Obtingut amb èxit";
			}
			if (
				response.data.status === "failed" &&
				response.data.success === undefined
			) {
				message = "No s'han trobat";
			}
		})
		.catch((error) => {
			console.log(error);
		});

	return { result, message };
};

const contacte = async (values) => {
	let message;
	let config = {
		headers: {
			"Content-Type": "multipart/form-data",
			Accept: "application/json",
			"Access-Control-Allow-Origin": "*",
		},
	};

	await axios.post(url + "contacte", values, config).then((response) => {
		if (response.status === 200) {
			message = "Missatge enviat!";
		}
		if (
			response.data.status === "failed" &&
			response.data.success === undefined
		) {
			message = "Error al enviar missatge. Prova-ho més tard";
		}
	});

	return message;
};

const createEntrada = async (values) => {
	let form;
	const data = new FormData();

	data.append("nom", values.nom);
	data.append("email", values.email);
	data.append("data", values.data);
	data.append("observacions", values.observacions);
	data.append("phone", values.phone);
	data.append("preu", values.preu);
	data.append("persones", JSON.stringify(values.persones));
	data.append("access", values.access);

	values.persones.map((item, index) => {
		data.append(`persones[${index}][nom]`, item?.nom);
		data.append(`persones[${index}][federat]`, item?.federat);
		data.append(`persones[${index}][dni]`, item?.dni);
		if (item.carnet?.[0]?.name) {
			data.append(
				`persones[${index}][carnet]`,
				item.carnet[0],
				item.carnet[0].name
			);
		}
	});

	let message;
	let config = {
		headers: {
			"Content-Type": "multipart/form-data",
			Accept: "application/json",
			"Access-Control-Allow-Origin": "*",
		},
	};

	await axios.post(url + "entrades", data, config).then((response) => {
		if (response.status === 200) {
			form = response.data;
			message = "Creant reserva... Esperi";
		}
		if (
			response.data.status === "failed" &&
			response.data.success === undefined
		) {
			message = "Error al reservar. Prova-ho més tard";
		}
	});

	return { form, message };
};

const changeState = async (values, id, element) => {
	const user = JSON.parse(localStorage.getItem("user"));
	let message;
	let config = {
		headers: {
			Accept: "application/json",
			"Access-Control-Allow-Origin": "*",
			Authorization: "Bearer " + user.token,
		},
	};

	await axios
		.post(url + element + "/" + id, { state_id: values }, config)
		.then((response) => {
			if (response.status === 200) {
				message = "Canvi d'estat finalitzat";
			}
			if (
				response.data.status === "failed" &&
				response.data.success === undefined
			) {
				message = "Error al canviar";
			}
		});

	return message;
};

const devolucio = async (values, key) => {
	const user = JSON.parse(localStorage.getItem("user"));
	let message;
	let resultat;
	let config = {
		headers: {
			"Access-Control-Allow-Origin": "*",
			Authorization: "Bearer " + user?.token,
		},
	};
	await axios
		.post(url + "retornar/" + key, values, config)
		.then((response) => {
			if (response.status === 200) {
				resultat = response.data;
				message = "Obtingut amb èxit";
			}
			if (
				response.data.status === "failed" &&
				response.data.success === undefined
			) {
				message = "No s'han trobat";
			}
		})
		.catch((error) => {
			console.log(error);
		});

	return { resultat, message };
};

const addElement = async (element, values) => {
	const user = JSON.parse(localStorage.getItem("user"));
	let message;
	let config = {
		headers: {
			"Content-Type": "multipart/form-data",
			"Access-Control-Allow-Origin": "*",
			Authorization: "Bearer " + user?.token,
		},
	};
	await axios
		.post(url + element, values, config)
		.then((response) => {
			if (response.status === 200) {
				message = response.data.message;
			}
			if (
				response.data.status === "failed" &&
				response.data.success === undefined
			) {
				message = response.data.message;
			}
		})
		.catch((error) => {
			console.log(error);
		});
	return { message };
};

const devolucioEntrada = async (values, key) => {
	const user = JSON.parse(localStorage.getItem("user"));
	let message;
	let resultat;
	let config = {
		headers: {
			"Access-Control-Allow-Origin": "*",
			Authorization: "Bearer " + user?.token,
		},
	};
	await axios
		.post(url + "retornarEntrada/" + key, values, config)
		.then((response) => {
			if (response.status === 200) {
				resultat = response.data;
				message = "Obtingut amb èxit";
			}
			if (
				response.data.status === "failed" &&
				response.data.success === undefined
			) {
				message = "No s'han trobat";
			}
		})
		.catch((error) => {
			console.log(error);
		});

	return { resultat, message };
};

export {
	Login,
	getUser,
	logout,
	getElements,
	getElement,
	getElementsAdmin,
	contacte,
	getElementAdmin,
	createEntrada,
	changeState,
	devolucio,
	devolucioEntrada,
	addElement,
};
