import { Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Box, Container } from "@mui/system";
import { useTranslation } from "react-i18next";

export default function Benvinguts() {
	const { t } = useTranslation();
	const classes = useStyles();
	return (
		<Container>
			<Box textAlign="center" className={classes.section}>
				<Typography variant="h1" mt={4}>
					{t("Benvinguts a Espai Actiu Vallcebre")}
				</Typography>
				<Typography variant="body1" mt={4}>
					{t(
						"Benvinguts a l’Espai Actiu Vallcebre, un lloc ideal per gaudir d’activitats d’aventura en un entorn natural privilegiat. Al municipi de Vallcebre, a l’alt Berguedà, envoltat per la Serra d’Ensija, els Cingles de Vallcebre, el Massís del Pedraforca, el parc natural del Cadí-Moixeró, i uns paisatges espectaculars. Podràs gaudir de les nostres instal·lacions de descens de barrancs, vies ferrades, escalada, camins equipats, i altres activitats per a tots els públics."
					)}
				</Typography>
			</Box>
		</Container>
	);
}

const useStyles = makeStyles((theme) => ({
	section: {
		paddingBottom: 300,
		paddingTop: 100,
	},
}));
