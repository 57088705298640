import React from "react";
import AdminLayout from "./layouts/AdminLayout";
import PublicLayout from "./layouts/PublicLayout";
import { Navigate } from "react-router-dom";
import HomeAdmin from "./views/Admin/HomeAdmin/HomeAdmin";
import LoginView from "./views/Auth/LogInView";
import Home from "./views/Public/Home/Home";
import ActivitatSingle from "./views/Public/Espais/EspaiSingle";
import Contacte from "./views/Public/Contacte/Contacte";
import Success from "./views/Public/Pagament/Success";
import Error from "./views/Public/Pagament/Error";
import CalendariAdmin from "./views/Admin/CalendariAdmin/CalendariAdmin";
import AvisLegal from "./views/Public/Politica/AvisLegal";
import PoliticaPrivacitat from "./views/Public/Politica/PoliticaPrivacitat";
import CondicionsGenerals from "./views/Public/Politica/CondicionsGenerals";
import CondicionsActivitats from "./views/Public/Politica/CondicionsActivitats";
import BenvingutsPage from "./views/Public/Benvinguts/BenvingutsPage";
import EspaisPage from "./views/Public/Espais/EspaisPage";
import Entrades from "./views/Public/Entrades/Entrades";
import EntradesAdmin from "./views/Admin/EntradesAdmin/EntradesAdmin";
import EntradaView from "./views/Admin/EntradesAdmin/EntradaView";
import Horaris from "./views/Public/Horaris/Horaris";

const routes = (logged) => [
	{
		path: "/admin",
		element: logged ? <AdminLayout /> : <Navigate to="/login" />,
		children: [
			{
				path: "/admin",
				element: <HomeAdmin title="Home" />,
			},
			{
				path: "/admin/calendari",
				element: <CalendariAdmin title="Calendari" />,
			},
			{
				path: "/admin/entrades",
				element: <EntradesAdmin title="Entrades" />,
			},
			{
				path: "/admin/entrades/:id",
				element: <EntradaView title="Entrades" />,
			},

			// { path: '/*', element: <NotFound /> },
		],
	},
	{
		path: "/",
		element: <PublicLayout />,
		children: [
			{
				path: "/login",
				element: !logged ? <LoginView /> : <Navigate to="/admin" />,
			},
			{ path: "/", element: <Home /> },
			{ path: "/espais", element: <EspaisPage /> },
			{ path: "/espais/:key", element: <ActivitatSingle /> },
			{ path: "/entrades", element: <Entrades /> },
			{ path: "/benvinguts", element: <BenvingutsPage /> },
			{ path: "/contacte", element: <Contacte /> },
			{ path: "/horaris", element: <Horaris /> },
			{ path: "/avis-legal", element: <AvisLegal /> },
			{ path: "/politica-privacitat", element: <PoliticaPrivacitat /> },
			{ path: "/condicions-generals", element: <CondicionsGenerals /> },
			{
				path: "/condicions-activitats",
				element: <CondicionsActivitats />,
			},
			{ path: "/exit/:id", element: <Success /> },
			{ path: "/error/:id", element: <Error /> },
		],
	},
];

export default routes;
