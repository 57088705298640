import { Box, Container, Grid, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react';
import { useLocation } from 'react-router';
import Breadcrumb from './Breadcrumb';

const useStyles = makeStyles((theme) => ({
    wrap: {
        backgroundColor: 'white',
        borderBottom: '1px solid ' + theme.palette.background.light,
        paddingTop: 8,
    },
}));

const Title = (props) => {
    const classes = useStyles();
    const { title } = props;
    const location = useLocation();

    return (
        <Container maxWidth="xl" className={classes.wrap}>
            <Grid container spacing={0}>
                <Grid item lg={12} md={12} xs={12}>
                    <Grid
                        container
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                    >
                        <Box>
                            <Typography
                                variant="h3"
                                component="h3"
                                gutterBottom
                                style={{ textTransform: 'uppercase' }}
                            >
                                {title}
                            </Typography>
                        </Box>
                        <Box>
                            <Typography variant="h4" gutterBottom>
                                <Breadcrumb locationPath={location?.pathname} />
                            </Typography>
                        </Box>
                    </Grid>
                </Grid>
            </Grid>
        </Container>
    );
};

export default Title;
