import * as React from "react";
import { Box, CircularProgress, Grid, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Activity, CheckSquare, Package } from "react-feather";
import { getElementsAdmin } from "../database/API";
import {
	AirplaneTicket,
	Cancel,
	CheckCircle,
	ConfirmationNumber,
	LocalMall,
	Money,
} from "@mui/icons-material";

const useStyles = makeStyles((theme) => ({
	kml: {
		borderRight: "1px solid " + theme.palette.background.light,
		paddingRight: 20,
		paddingLeft: 20,
		paddingTop: 25,
		paddingBottom: 25,
	},
	icon: {
		paddingRight: 10,
	},
	loading: {
		fontSize: 10,
	},
}));

export default function KPI() {
	const classes = useStyles();
	const [kpi, setKpi] = React.useState();
	const [loading, setLoading] = React.useState(true);

	React.useEffect(() => {
		const get = async () => {
			const { result } = await getElementsAdmin("kpiEntrades");
			setKpi(result);
			setLoading(false);
		};
		get();
	}, []);

	return (
		<Box>
			<Grid
				container
				sx={{
					alignItems: "center",
					borderRadius: 2,
					bgcolor: "background.paper",
					color: "text.secondary",
					padding: 0,
				}}
			>
				<Grid item md={3} className={classes.kml}>
					<Typography variant="h6">Totals</Typography>
					<Box display="flex" alignItems={"center"} mt={1}>
						<ConfirmationNumber
							sx={{ width: 40, height: 40 }}
							className={classes.icon}
						/>
						{loading ? (
							<CircularProgress size={28} />
						) : (
							<Typography variant="h2">
								{kpi?.entrades_totals}
							</Typography>
						)}
					</Box>
				</Grid>
				<Grid item md={3} className={classes.kml}>
					<Typography variant="h6">Pagades </Typography>
					<Box display="flex" alignItems={"center"} mt={1}>
						<Money
							sx={{ width: 40, height: 40 }}
							className={classes.icon}
						/>
						{loading ? (
							<CircularProgress size={28} />
						) : (
							<Typography variant="h2">
								{kpi?.entrades_pagades}
							</Typography>
						)}
					</Box>
				</Grid>

				<Grid item md={3} className={classes.kml}>
					<Typography variant="h6">Completades </Typography>
					<Box display="flex" alignItems={"center"} mt={1}>
						<CheckCircle
							sx={{ width: 40, height: 40 }}
							className={classes.icon}
						/>
						{loading ? (
							<CircularProgress size={28} />
						) : (
							<Typography variant="h2">
								{kpi?.entrades_completades}
							</Typography>
						)}
					</Box>
				</Grid>

				<Grid item md={3} className={classes.kml}>
					<Typography variant="h6">Cancelades </Typography>
					<Box display="flex" alignItems={"center"} mt={1}>
						<Cancel
							sx={{ width: 40, height: 40 }}
							className={classes.icon}
						/>
						{loading ? (
							<CircularProgress size={28} />
						) : (
							<Typography variant="h2">
								{kpi?.entrades_cancelades}
							</Typography>
						)}
					</Box>
				</Grid>
			</Grid>
		</Box>
	);
}
