import * as React from 'react';
import { styled } from '@mui/material/styles';
import { Box, Card, CardActions, CardContent, CardHeader } from '@mui/material';
import theme from '../theme';

const Custom = styled(Card)({
    boxShadow: 'none',
});

const CustomHeader = styled(CardHeader)({
    '& .MuiTypography-root': {
        fontSize: 17,
        fontWeight: 400,
    },
    borderBottom: '1px solid ' + theme.palette.background.light,
});

const CustomActions = styled(CardActions)({
    justifyContent: 'flex-end',
});

export default function CustomCard({ children, title, footer }) {
    return (
        <Custom>
            <CustomHeader title={title} />
            <CardContent>{children}</CardContent>
            {footer && (
                <>
                    <Box my={3} />
                    <CustomActions>{footer}</CustomActions>
                </>
            )}
        </Custom>
    );
}
