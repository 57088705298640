import React from "react";
import Header from "../../../components/Header";
import Page from "../../../components/Layout/Page";
import Sanefa from "../../../components/Sanefa";
import img from "../../../assets/img/espais/escalada.jpg";
import usePageTracking from "../../../layouts/TrakingGA";
import theme from "../../../theme";
import Benvinguts from "./Seccions/Benvinguts";
import QueEs from "./Seccions/QueEs";
import QuiGestiona from "./Seccions/QuiGestiona";
import QueCal from "./Seccions/QueCal";
import Serveis from "./Seccions/Serveis";
import { useTranslation } from "react-i18next";

const BenvingutsPage = () => {
	usePageTracking();
	const { t } = useTranslation();

	return (
		<Page title="Espai Actiu Vallcebre - Benvinguts">
			<Header title={t("Què és?")} img={img} />
			<Sanefa color="white" />
			<Benvinguts />
			<Sanefa color={theme.palette.background.secondary} />
			<QueEs />
			<Sanefa inverted color={theme.palette.background.secondary} />
			<QuiGestiona />
			<Sanefa color={theme.palette.background.third} />
			<QueCal />
			<Sanefa inverted color={theme.palette.background.third} />
			<Serveis />
		</Page>
	);
};

export default BenvingutsPage;
