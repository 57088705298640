import React from "react";
import { Box, Grid, Typography, useMediaQuery } from "@mui/material";
import { makeStyles } from "@mui/styles";
import Sanefa from "../../components/Sanefa";
import { ReactComponent as Icon } from "../../assets/logo.svg";
import { ReactComponent as LogoPendol } from "../../assets/img/logo-pendol.svg";
import { useTranslation } from "react-i18next";
import theme from "../../theme";
import ajuntament from "../../assets/img/logos/ajuntament.png";
import bergueda from "../../assets/img/logos/bergueda.png";
import diputacio from "../../assets/img/logos/diputacio.png";
import europa from "../../assets/img/logos/europa.png";
import generalitat from "../../assets/img/logos/generalitat.png";
import pedraforca from "../../assets/img/logos/pedraforca.png";
import imparables from "../../assets/img/logos/imparables.png";
import sx3 from "../../assets/img/logos/sx3.png";

const Footer = () => {
	const classes = useStyles();
	const { t } = useTranslation();
	const matches = useMediaQuery("(min-width:960px)");

	return (
		<Box className={classes.root}>
			<Sanefa color={theme.palette.background.secondary} />

			<Box className={classes.main}>
				<Box className={classes.header}>
					<a href="/">
						<Icon
							alt="Logo Espai Actiu Vallcebre"
							width={matches ? 400 : 280}
							className={classes.logo}
						/>
					</a>
					<Box my={4}>
						<LogoPendol
							height={40}
							fill="white"
							style={{ paddingBottom: 15 }}
						/>

						<img
							src={pedraforca}
							height={40}
							style={{ paddingBottom: 15 }}
							alt="Valls del Pedraforca"
						/>
						<img
							src={ajuntament}
							height={40}
							style={{ paddingBottom: 15 }}
							alt="Ajuntament de Vallcebre"
						/>
						<img
							src={bergueda}
							height={40}
							style={{ paddingBottom: 15 }}
							alt="Berguedà Terra de Futurs"
						/>

						<img
							src={imparables}
							height={40}
							style={{ paddingBottom: 15 }}
							alt="Imparables.cat"
						/>
						<img
							src={diputacio}
							height={40}
							style={{ paddingBottom: 15 }}
							alt="Diputació de Barcelona"
						/>
						<img
							src={sx3}
							height={40}
							alt="SX3"
							style={{ paddingBottom: 15 }}
						/>
						<img
							src={generalitat}
							height={40}
							style={{ paddingBottom: 15 }}
							alt="Generalitat de Catalunya"
						/>
						<img
							src={europa}
							height={40}
							alt="Unió Europea"
							style={{ paddingBottom: 15 }}
						/>
					</Box>
					<Box
						display={"flex"}
						justifyContent="center"
						alignItems={"center"}
						flexDirection={matches ? "row" : "column"}
						mt={2}
					>
						<a
							href="/avis-legal"
							style={{
								textDecoration: "none",
								cursor: "pointer",
								paddingLeft: 10,
								paddingRight: 10,
							}}
						>
							<Typography color="white">
								{t("Avís Legal")}
							</Typography>
						</a>

						<a
							href="/politica-privacitat"
							style={{
								textDecoration: "none",
								cursor: "pointer",
								paddingLeft: 10,
								paddingRight: 10,
							}}
						>
							<Typography color="white">
								{t("Política de privacitat")}
							</Typography>
						</a>
						<a
							href="/condicions-generals"
							style={{
								textDecoration: "none",
								cursor: "pointer",
								paddingLeft: 10,
								paddingRight: 10,
							}}
						>
							<Typography color="white">
								{t("Condicions generals")}
							</Typography>
						</a>
					</Box>
				</Box>
			</Box>
		</Box>
	);
};

export default Footer;

const useStyles = makeStyles((theme) => ({
	main: {
		backgroundColor: theme.palette.background.secondary,
		paddingTop: 100,
		paddingBottom: 100,
		marginTop: -5,
	},
	header: {
		textAlign: "center",
		justifyContent: "center",
		alignItems: "center",
		display: "flex",
		flexDirection: "column",
	},
	logo: {
		fill: "white",
		"& .cls-1": {
			fill: "white",
			opacity: 1,
		},
	},
}));
