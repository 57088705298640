import { Box, Container, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import Header from "../../../components/Header";
import img from "../../../assets/img/espais/barranquisme.jpg";
import Page from "../../../components/Layout/Page";
import Sanefa from "../../../components/Sanefa";
import { CheckCircle } from "@mui/icons-material";

const Success = () => {
	const classes = Styles();
	const { t } = useTranslation();

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	return (
		<Page title="Espai Actiu Vallcebre - Entrada">
			<Header title={t("Èxit!")} img={img} />
			<Sanefa color="white" />
			<Box className={classes.root}>
				<Container>
					<Box mt={4}>
						<Typography variant="h1" className={classes.titol}>
							{t("Moltes gràcies")}
						</Typography>
						<Box className={classes.titol}>
							<CheckCircle sx={{ fontSize: 60 }} />
							<Typography variant="h2" style={{ color: "green" }}>
								{t("Pagament efectuat amb èxit")}
							</Typography>
						</Box>
						<Box textAlign="center" mt={2}>
							<Typography variant="h3">
								{t("Consulta l'E-mail per a més informació")}
							</Typography>
						</Box>
					</Box>
				</Container>
			</Box>
		</Page>
	);
};

const Styles = makeStyles((theme) => ({
	main: {
		paddingBottom: 50,
	},
	root: {
		paddingTop: 150,
		paddingBottom: 300,
		overflow: "auto",
		justifyContent: "center",
	},

	titol: {
		color: "green",
		display: "flex",
		justifyContent: "center",
	},
}));

export default Success;
