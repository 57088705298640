import { Cancel, Close } from "@mui/icons-material";
import { Box, Container, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React from "react";
import { useTranslation } from "react-i18next";
import Header from "../../../components/Header";
import Page from "../../../components/Layout/Page";
import Sanefa from "../../../components/Sanefa";
import img from "../../../assets/img/espais/barranquisme.jpg";

const Error = () => {
	const classes = Styles();
	const { t } = useTranslation();
	return (
		<Page title="Espai Actiu Vallcebre - Entrada">
			<Header title={t("Fallada")} img={img} />
			<Sanefa color="white" />
			<Box className={classes.root}>
				<Container>
					<Box mt={4}>
						<Typography variant="h1" className={classes.titol}>
							{t("Ups...")}
						</Typography>
						<Box className={classes.titol}>
							<Close sx={{ fontSize: 60, color: "red" }} />
							<Typography variant="h2" style={{ color: "red" }}>
								{t("Pagament fallat")}
							</Typography>
						</Box>
						<Box textAlign="center" mt={2}>
							<Typography variant="h3">
								{t(
									"El pagament ha fallat... Torna-ho a provar més tard"
								)}
							</Typography>
						</Box>
					</Box>
				</Container>
			</Box>
		</Page>
	);
};

const Styles = makeStyles((theme) => ({
	main: {
		paddingBottom: 50,
	},
	root: {
		paddingTop: 150,
		paddingBottom: 300,
		backgroundSize: "cover",
		overflow: "auto",
		justifyContent: "center",
	},

	titol: {
		color: "green",
		display: "flex",
		justifyContent: "center",
	},
}));

export default Error;
