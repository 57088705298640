import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import ReactGA from "react-ga";

const usePageTracking = () => {
	const location = useLocation();
	console.log(location.pathname);

	useEffect(() => {
		ReactGA.initialize("G-Y6MH5FMTGZ");
		ReactGA.pageview(location.pathname + location.search);
	}, [location]);
};

export default usePageTracking;
