import React, { useEffect, useState } from "react";
import clsx from "clsx";
import { makeStyles } from "@mui/styles";
import {
	Box,
	Dialog,
	DialogContent,
	DialogTitle,
	FormControl,
	InputLabel,
	Select,
	Tooltip,
	Typography,
} from "@mui/material";
import CustomSelect from "./CustomSelect";
import CustomPublicButton from "./CustomPublicButton";
import { changeState } from "../database/API";
import { useSnackbar } from "notistack";

const useStyles = makeStyles((theme) => ({
	icon: { padding: 5 },
	badge: {
		paddingTop: 5,
		paddingBottom: 5,
		paddingRight: 10,
		paddingLeft: 10,
		borderRadius: 15,
		color: "white",
		textAlign: "center",
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		transition: "0.2s",
		cursor: "pointer",
		"&:hover": {
			boxShadow: "0px 0px 5px 0px " + theme.palette.background.main,
		},
	},
	espera: {
		backgroundColor: "#ababab",
	},
	completada: {
		backgroundColor: "#8bc34a",
	},
	cancelada: {
		backgroundColor: "#ff1744",
	},
	pagada: {
		backgroundColor: "#03a9f4",
	},
}));

const State = (props) => {
	const classes = useStyles();
	const { state, id, element } = props;
	const [open, setOpen] = useState(false);
	const [newState, setNewState] = useState();
	const [newLabel, setNewLabel] = useState(state.nom);
	const { enqueueSnackbar } = useSnackbar();

	const style =
		newLabel === "Pagat"
			? classes.pagada
			: newLabel === "Completat"
			? classes.completada
			: newLabel === "En espera"
			? classes.espera
			: classes.cancelada;

	const canvi = async (val) => {
		const message = await changeState(val, id, element);
		enqueueSnackbar(message, {
			variant: "success",
		});
	};

	return (
		<>
			<Tooltip title="Clica per canviar d'estat">
				<Box
					className={clsx(classes.badge, style)}
					onClick={() => setOpen(true)}
				>
					<Typography style={{ color: "white", fontSize: 12 }}>
						{newLabel}
					</Typography>
				</Box>
			</Tooltip>
			<Dialog
				open={open}
				onClose={() => setOpen(false)}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
				disableScrollLock
				maxWidth="md"
			>
				<DialogTitle>Canvi d'estat</DialogTitle>
				<DialogContent>
					<Box>
						<FormControl
							fullWidth
							style={{ marginTop: 20, width: 300 }}
						>
							<InputLabel shrink={true} htmlFor={"state_id"}>
								{"Nou estat"}
							</InputLabel>
							<Select
								native
								label={"Nou estat"}
								name={"state_id"}
								value={newState}
								defaultValue={state.id}
								notched={true}
								onChange={(val) => {
									setNewState(val.target.value);
									setNewLabel(
										val.target.selectedOptions[0].innerHTML
									);
									canvi(val.target.value);
									setOpen(false);
								}}
							>
								<option value={1}>En espera</option>
								<option value={2}>Pagat</option>
								<option value={3}>Completat</option>
								<option value={4}>Cancel·lat</option>
								<option value={5}>Devolució</option>
							</Select>
						</FormControl>
						<Box my={2} />
					</Box>
				</DialogContent>
			</Dialog>
		</>
	);
};

export default State;
