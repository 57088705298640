import * as React from "react";
import {
	Avatar,
	Box,
	Chip,
	Divider,
	Grid,
	IconButton,
	Skeleton,
	Tooltip,
	Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import {
	Check,
	CheckCircle,
	ExternalLink,
	Link,
	Link2,
	MapPin,
	User,
} from "react-feather";
import { useNavigate } from "react-router";
import clsx from "clsx";
import moment from "moment";
import StateColumn from "../StateColumn";
import theme from "../../theme";

const useStyles = makeStyles((theme) => ({
	wrap: {
		paddingLeft: 10,
		backgroundColor: "white",
		borderRadius: 10,
		borderLeft: "5px solid",
		marginBottom: 20,
		height: 90,
	},
}));

export default function ReservaSkeleton({ reserva }) {
	const classes = useStyles();

	return (
		<Box>
			<Skeleton
				variant="rectangular"
				className={classes.wrap}
				height={110}
			/>
			<Skeleton
				variant="rectangular"
				className={classes.wrap}
				height={110}
			/>
			<Skeleton
				variant="rectangular"
				className={classes.wrap}
				height={110}
			/>
			<Skeleton
				variant="rectangular"
				className={classes.wrap}
				height={110}
			/>
		</Box>
	);
}
