import React from "react";

import PropTypes from "prop-types";
import navBarStyle from "./NavBarStyle";
import { Typography, Link as LinkTo, useMediaQuery } from "@mui/material";
import { NavLink as RouterLink } from "react-router-dom";
import { Box } from "@mui/system";

const NavBarItem = ({ className, to, title, href, ...rest }) => {
	const classes = navBarStyle();
	const matches = useMediaQuery("(min-width:1480px)");

	return href ? (
		<a
			href={href}
			className={classes.button}
			target="_blank"
			rel="noreferrer"
			style={{
				paddingLeft: matches ? 20 : 10,
				paddingRight: matches ? 20 : 10,
			}}
		>
			<Typography variant="link" className={classes.title}>
				{title}
			</Typography>
		</a>
	) : (
		<LinkTo
			className={classes.button}
			to={{
				pathname: to,
			}}
			underline="none"
			component={RouterLink}
			style={{
				paddingLeft: matches ? 20 : 10,
				paddingRight: matches ? 20 : 10,
			}}
		>
			<Typography variant="link" className={classes.title}>
				{title}
			</Typography>
		</LinkTo>
	);
};

NavBarItem.propTypes = {
	className: PropTypes.string,
	href: PropTypes.string,
	icon: PropTypes.elementType,
	title: PropTypes.string,
};

export default NavBarItem;
