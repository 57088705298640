import * as yup from "yup";
import { useTranslation } from "react-i18next";

const SchemaRes = () => {
	// const { t } = () => {};
	const { t } = useTranslation();

	const schema = yup.object().shape({
		persones: yup.array().of(
			yup.object().shape({
				nom: yup.string().required(t("Nom obligatori")),
				cognom1: yup.string().required(t("Cognom obligatori")),
				cognom2: yup.string().required(t("Cognom obligatori")),
				email: yup.string().when("menor", {
					is: false,
					then: yup
						.string()
						.email(t("Ha de ser un E-mail vàlid"))
						.max(255)
						.required(t("E-mail obligatori")),
				}),
				confirm_email: yup.string().when("menor", {
					is: false,
					then: yup
						.string()
						.required(t("Confirmació obligatòria"))
						.oneOf(
							[yup.ref("email")],
							t("Els e-mails no coincidèixen")
						),
				}),
				dniDarrere: yup.mixed().when("menor", {
					is: false,
					then: yup
						.mixed()
						.test(
							"required",
							t("La fotografia del document és obligatòria"),
							(file) => {
								return file[0] && file[0].size;
							}
						),
				}),
			})
		),
	});

	const schemaReserva = yup.object({
		nom: yup.string().required(t("Nom obligatori")),
		observacions: yup.string(),
		email: yup
			.string()
			.email(t("Ha de ser un E-mail vàlid"))
			.max(255)
			.required(t("E-mail obligatori")),
		phone: yup.string().required(t("Telèfon obligatori")),
		data: yup.string().required(t("Data obligatoria")),
		// preu: yup.number().required('El preu és obligatòri').min(1),
		// persones: yup
		//     .number()
		//     .min(2, 'Mínim 2 persones')
		//     .max(8, 'Més de 8 persones, consultar a Contacte')
		//     .required(t('El/s nom/s a qui va dirigit el regal és obligatori')),
		persones: yup
			.array()
			.of(
				yup.object().shape({
					nom: yup.string().max(255).required().label("Nom"),
				})
			)
			.test({
				message: "Ha d'entrar com a mínim una persona",
				test: (arr) => arr.length > 0,
			}),
	});

	const schemaLloguer = yup.object({
		nom: yup.string().required(t("Nom obligatori")),
		email: yup
			.string()
			.email(t("Ha de ser un E-mail vàlid"))
			.max(255)
			.required(t("E-mail obligatori")),
		phone: yup.string().required(t("Telèfon obligatori")),
	});

	const schemaContacte = yup.object({
		nom: yup.string().required(t("Nom obligatori")),
		missatge: yup.string().required(t("El missatge és obligatori")),
		email: yup
			.string()
			.email(t("Ha de ser un E-mail vàlid"))
			.max(255)
			.required(t("E-mail obligatori")),
	});

	return { schema, schemaReserva, schemaContacte, schemaLloguer };
};

const schemaActivitat = yup.object().shape({
	persones: yup.array().of(
		yup.object().shape({
			nom: yup.string().required("Nom obligatori"),
			cognom1: yup.string().required("Cognom obligatori"),
			cognom2: yup.string().required("Cognom obligatori"),
		})
	),
});

export { SchemaRes, schemaActivitat };
