import { Box, Fade } from '@mui/material';

export default function LoadingSkeleton({ loading, children, skeleton }) {
    return (
        <Box>
            {!loading ? (
                <Fade in={!loading}>{children}</Fade>
            ) : (
                <Fade in={loading}>
                    <Box>{skeleton}</Box>
                </Fade>
            )}
        </Box>
    );
}
