import { CircleRounded, Close } from "@mui/icons-material";
import {
	Button,
	Container,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Grid,
	IconButton,
	Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import moment from "moment";
import { Delete } from "react-feather";
import { useTranslation } from "react-i18next";
import CalendariDisponibilitat from "../../../components/CalendariDisponibilitat";
import dies from "../../../data/TipusDies";

export default function DialogCalendari({ open, setOpen }) {
	const { t } = useTranslation();

	return (
		<Dialog
			open={open}
			onClose={() => setOpen(false)}
			aria-labelledby="alert-dialog-title"
			aria-describedby="alert-dialog-description"
			maxWidth="lg"
		>
			<DialogContent>
				<Typography textAlign={"center"} variant="h3" my={5}>
					{t("Calendari de disponibilitat")} {moment().format("YYYY")}
				</Typography>
				<Container>
					<Box display="flex" mb={5} justifyContent={"space-between"}>
						{dies.map((item) => (
							<Box
								key={item.id}
								display="flex"
								alignItems={"center"}
							>
								<CircleRounded
									sx={{
										width: 18,
										fill: item.color,
										zIndex: 0,
										marginRight: 1,
									}}
								/>
								<Typography variant="caption">
									{t(item.text)}
								</Typography>
							</Box>
						))}
					</Box>
				</Container>
				<CalendariDisponibilitat />
				<Box position={"absolute"} right={15} top={15}>
					<IconButton onClick={() => setOpen(false)}>
						<Close sx={{ width: 40, height: 40 }} />
					</IconButton>
				</Box>
			</DialogContent>
			<DialogActions>
				<Button onClick={() => setOpen(false)}>{t("Tancar")}</Button>
			</DialogActions>
		</Dialog>
	);
}
