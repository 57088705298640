import {
	Box,
	Checkbox,
	Container,
	FormControlLabel,
	Grid,
	Typography,
} from "@mui/material";
import React, { useState } from "react";
import Header from "../../../components/Header";
import img from "../../../assets/img/espais/via.jpg";
import Sanefa from "../../../components/Sanefa";
import { makeStyles } from "@mui/styles";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { SchemaRes } from "../../../components/Schemas";
import CustomPublicField from "../../../components/Activitats/CustomPublicField";
import Page from "../../../components/Layout/Page";
import IconList from "../../../components/Activitats/IconList";
import { Mail, Phone } from "react-feather";
import CustomPublicButton from "../../../components/CustomPublicButton";
import { useSnackbar } from "notistack";
import { contacte } from "../../../database/API";
import { useTranslation } from "react-i18next";
import { WhatsApp } from "@mui/icons-material";

const Contacte = () => {
	const classes = useStyles();
	const { schemaContacte } = SchemaRes();
	const { enqueueSnackbar } = useSnackbar();
	const [loading, setLoading] = useState(false);
	const { t } = useTranslation();

	const {
		register,
		handleSubmit,
		formState: { errors },
		reset,
	} = useForm({
		resolver: yupResolver(schemaContacte),
	});

	const enviar = async (values) => {
		setLoading(true);
		const message = await contacte(values);
		enqueueSnackbar(message, {
			variant: "success",
		});
		reset();
		setLoading(false);
	};

	return (
		<Page
			title={"Espai Actiu Vallcebre - " + t("Contacta i ubicació")}
			className={classes.main}
		>
			<Header title={t("Contacta i ubicació")} img={img} />
			<Sanefa color="white" />
			<Box className={classes.filters}>
				<form onSubmit={handleSubmit(enviar)}>
					<Container>
						<Typography mb={2} variant="h1">
							{t("Posa't en contacte")}
						</Typography>
						<Typography variant="body1" mb={4}>
							{t(
								"Posa’t en contacte amb nosaltres i et contestarem amb molt de gust, el més aviat possible. Si tens algún pregunta o suggerencia per realitzar alguna activitat, no ho dubtis i escriu-nos!"
							)}
						</Typography>
						<Grid container spacing={5} mb={6}>
							<Grid item md={8} xs={12}>
								<Grid container spacing={3}>
									<Grid item md={12} xs={12}>
										<CustomPublicField
											name="nom"
											label={t("Nom i cognoms")}
											type="text"
											errors={errors.nom}
											register={register}
										/>
									</Grid>
									<Grid item md={12} xs={12}>
										<CustomPublicField
											name="email"
											label="E-mail"
											type="email"
											errors={errors.email}
											register={register}
										/>
									</Grid>
									<Grid item md={12} xs={12}>
										<CustomPublicField
											name="missatge"
											label={t("Missatge")}
											type="text"
											errors={errors.missatge}
											register={register}
											rows={5}
											multiline
										/>
									</Grid>
								</Grid>
								<FormControlLabel
									control={
										<Checkbox
											onChange={(e) => {}}
											name="accepta"
											{...register("accepta")}
											required
										/>
									}
									label={
										<Typography>
											{t("He llegit i accepto la")}{" "}
											<a
												href="/politica-privacitat"
												target="_blank"
												alt="Política de Privacitat"
												rel="noreferrer"
											>
												{"Política de Privacitat"}
											</a>
										</Typography>
									}
								/>
								<Box my={3} />
								<CustomPublicButton
									type="submit"
									title={t("Enviar")}
									loading={loading}
									disabled={loading}
								/>
							</Grid>
							<Grid item md={4} xs={12}>
								<Box className={classes.block}>
									<Typography variant="body1">
										{t(
											"O si ho prefereixes, també ens pots contactar mitjançant les següents vies:"
										)}
									</Typography>
									<IconList
										icon={<Phone />}
										pb={2}
										label="+34 650 85 34 79"
									/>
									<IconList
										icon={<Phone />}
										pb={2}
										label="+34 622 76 61 52"
									/>
									<IconList
										icon={<WhatsApp />}
										pb={2}
										label={
											<a
												href="https://wa.me/34622766152"
												target={"_blank"}
												alt="WhatsApp"
												rel="noreferrer"
											>
												WhatsApp
											</a>
										}
									/>
									<IconList
										icon={<Mail />}
										pb={2}
										label={
											<a href="mailto:info@pendolguies.com">
												info@pendolguies.com
											</a>
										}
									/>
								</Box>
							</Grid>
						</Grid>
						<Typography mb={2} variant="h1">
							{t("On trobar-nos")}
						</Typography>
						<iframe
							src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d366.9064292952419!2d1.8041697544223567!3d42.20016811706701!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x12a5a1c84aeba009%3A0xc2172c1408b44da!2sEspai%20Actiu%20Vallcebre%2C%20Escola%20de%20muntanya!5e0!3m2!1ses!2ses!4v1680526385491!5m2!1ses!2ses"
							width="100%"
							height="450"
							style={{ border: 0, borderRadius: 20 }}
							allowfullscreen=""
							loading="lazy"
							referrerpolicy="no-referrer-when-downgrade"
						></iframe>
					</Container>
				</form>
			</Box>
		</Page>
	);
};

export default Contacte;

const useStyles = makeStyles((theme) => ({
	filters: {
		paddingTop: 100,
		paddingBottom: 300,
	},
	block: {
		border: "1px solid " + theme.palette.background.third,
		borderRadius: 5,
		padding: 20,
		marginBottom: 20,
	},
}));
