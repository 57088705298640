import { Cancel } from "@mui/icons-material";
import {
	Box,
	Container,
	Paper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import React from "react";
import { useTranslation } from "react-i18next";
import Header from "../../../components/Header";
import img from "../../../assets/img/espais/barranquisme.jpg";
import Sanefa from "../../../components/Sanefa";

const PoliticaPrivacitat = () => {
	const classes = Styles();
	const { t } = useTranslation();
	return (
		<Box className={classes.root}>
			<Header title="Política de Privacitat" img={img} />
			<Sanefa color="white" />
			<Container maxWidth="lg" className={classes.main}>
				<Typography>
					A PÈNDOL GUIES DE MUNTANYA S.C.P ens preocupem per la
					privadesa i la transparència.
				</Typography>{" "}
				<Typography>
					A continuació, us indiquem detalladament els tractaments de
					dades personals que realitzem, així com tota la informació
					relativa a aquestes.
				</Typography>{" "}
				<Typography>
					Tractament de les dades de clients, potencials clients i
					contactes web
				</Typography>
				<Box my={4}>
					<TableContainer component={Paper}>
						<Table sx={{ minWidth: 650 }} aria-label="simple table">
							<TableBody>
								<TableRow
									sx={{
										"&:last-child td, &:last-child th": {
											border: 0,
										},
									}}
								>
									<TableCell component="th" scope="row">
										Responsable
									</TableCell>
									<TableCell align="right">
										Finalitat
									</TableCell>
									<TableCell align="right">
										Legitimació
									</TableCell>
									<TableCell align="right">
										Destinataris
									</TableCell>
									<TableCell align="right">Drets</TableCell>
									<TableCell align="right">
										Procedència
									</TableCell>
								</TableRow>
								<TableRow
									sx={{
										"&:last-child td, &:last-child th": {
											border: 0,
										},
									}}
								>
									<TableCell component="th" scope="row">
										PÈNDOL GUIES DE MUNTANYA S.C.P
									</TableCell>
									<TableCell align="right">
										Realitzar els processos de selecció de
										personal.
									</TableCell>
									<TableCell align="right">
										Execució dun contracte.
									</TableCell>
									<TableCell align="right">
										No se cediran dades a tercers, excepte
										obligació legal.
									</TableCell>
									<TableCell align="right">
										Té dret a accedir, rectificar i suprimir
										les dades, així com altres drets,
										indicats a la informació addicional, que
										pot exercir dirigint-se a la direcció
										del responsable del tractament
									</TableCell>
									<TableCell align="right">
										El mateix interessat
									</TableCell>
								</TableRow>
							</TableBody>
						</Table>
					</TableContainer>
				</Box>
				<Typography>
					Informació completa sobre Protecció de Dades
				</Typography>
				<Typography variant="h2" className={classes.titol}>
					1. Qui és el responsable del tractament de les vostres
					dades?
				</Typography>
				<Typography>PÈNDOL GUIES DE MUNTANYA S.C.P</Typography>{" "}
				<Typography>J09920877</Typography>{" "}
				<Typography>
					C/ de l’alberg S/N, 08699, Vallcebre, Barcelona
				</Typography>{" "}
				<Typography>(34) 650 85 34 79</Typography>
				<Typography>info@pendolguies.cat</Typography>
				<Typography variant="h2" className={classes.titol}>
					2. Amb quina finalitat tractem les vostres dades personals?
				</Typography>
				<Typography>
					A PÈNDOL GUIES DE MUNTANYA S.C.P tractem la informació que
					ens faciliten les persones interessades per tal de gestionar
					els Curriculum Vitae rebuts i realitzar els processos de
					selecció de personal, entrevistes i altres tràmits
					necessaris per a la cerca del millor candidat possible a un
					lloc de treball determinat. En cas que no faciliteu les
					vostres dades personals, no podrem complir amb les
					finalitats descrites.
				</Typography>{" "}
				<Typography>
					No es prendran decisions automatitzades en base a les dades
					proporcionades.
				</Typography>{" "}
				<Typography variant="h2" className={classes.titol}>
					3. Per quant de temps conservarem les vostres dades?
				</Typography>
				<Typography>Dos anys des de la darrera interacció.</Typography>{" "}
				<Typography variant="h2" className={classes.titol}>
					4. Quina és la legitimació per al tractament de les vostres
					dades?
				</Typography>
				<Typography>
					Us indiquem la base legal per al tractament de les vostres
					dades:
				</Typography>{" "}
				<Typography>
					• Execució d'un contracte: Gestió dels Curriculum Vitae
					lliurats pel candidat per fer els processos de selecció de
					personal per a la recerca del millor candidat possible a un
					lloc de treball determinat. (RGPD art. 6.1.b).
				</Typography>{" "}
				<Typography variant="h2" className={classes.titol}>
					5. A quins destinataris es comunicaran les vostres dades?
				</Typography>
				<Typography>
					No se cediran dades a tercers, excepte obligació legal.
				</Typography>
				<Typography variant="h2" className={classes.titol}>
					6. Transferències de dades a tercers països
				</Typography>
				<Typography>
					No estan previstes transferències de dades a tercers països.
				</Typography>
				<Typography variant="h2" className={classes.titol}>
					7. Quins són els vostres drets quan ens facilita les vostres
					dades?
				</Typography>
				<Typography>
					Qualsevol persona té dret a obtenir confirmació sobre si a
					PÈNDOL GUIES DE MUNTANYA S.C.P estem tractant, o no, dades
					personals que els concerneixin.
				</Typography>
				<Typography>
					Les persones interessades tenen dret a accedir a les seves
					dades personals, així com a sol·licitar la rectificació de
					les dades inexactes o, si escau, sol·licitar la seva
					supressió quan, entre altres motius, les dades ja no siguin
					necessàries per als fins que van ser recollides. Igualment,
					té dret a la portabilitat de les seves dades.
				</Typography>
				<Typography>
					En determinades circumstàncies, els interessats podran
					sol·licitar la limitació del tractament de les seves dades,
					cas en què únicament les conservarem per a l'exercici o la
					defensa de reclamacions.
				</Typography>
				<Typography>
					En determinades circumstàncies i per motius relacionats amb
					la seva situació particular, els interessats podran
					oposar-se al tractament de les dades. En aquest cas, PÈNDOL
					GUIES DE MUNTANYA S.C.P deixarà de tractar les dades, tret
					de motius legítims imperiosos, o l'exercici o la defensa de
					possibles reclamacions.{" "}
				</Typography>
				<Typography>
					Podrà exercitar materialment els seus drets de la manera
					següent: dirigint-se a la direcció del responsable del
					tractament
				</Typography>
				<Typography>
					Quan es faci l'enviament de comunicacions comercials
					utilitzant com a base jurídica l'interès legítim del
					responsable, l'interessat es pot oposar al tractament de les
					dades amb aquesta finalitat.
				</Typography>
				<Typography>
					Si heu atorgat el vostre consentiment per a alguna finalitat
					concreta, teniu dret a retirar el consentiment atorgat en
					qualsevol moment, sense que això afecti la licitud del
					tractament basat en el consentiment previ a la seva
					retirada.
				</Typography>
				<Typography>
					En cas que senti vulnerats els seus drets pel que fa a la
					protecció de les seves dades personals, especialment quan no
					hagi obtingut satisfacció en l'exercici dels seus drets, pot
					presentar una reclamació davant de l'Autoritat de Control en
					matèria de protecció de dades competent a través del vostre
					lloc web: www.aepd.es.
				</Typography>
				<Typography variant="h2" className={classes.titol}>
					8. Com hem obtingut les vostres dades?
				</Typography>
				<Typography>
					Les dades personals que tractem a PÈNDOL GUIES DE MUNTANYA
					S.C.P procedeixen de: El mateix interessat.
				</Typography>
				<Typography>
					Les categories de dades que es tracten són:
				</Typography>
				<Typography>• Dades identificatives.</Typography>
				<Typography>• Adreces postals i electròniques.</Typography>
			</Container>
		</Box>
	);
};

const Styles = makeStyles((theme) => ({
	main: {
		paddingTop: 100,
		paddingBottom: 250,
	},
	root: {
		backgroundSize: "cover",
		overflow: "auto",
		justifyContent: "center",
	},

	titol: {
		display: "flex",
		justifyContent: "flex-start",
		fontSize: 25,
		paddingTop: 40,
		paddingBottom: 20,
	},
}));

export default PoliticaPrivacitat;
